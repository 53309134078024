<template>
    <div>
        <button @click="generatePDF">Generate PDF</button>
        <button @click="next">NEXT</button>
        <div id="flashcards-table">
            <table id="flashcards-target">
                <tr v-for="(wordlist, index) in wordsBroken" :key="index">
                    <td v-for="(word, index) in wordlist" :key="index">
                        <p>{{ word.sentence1 }}</p>
                        <p>{{ word.rootId }}</p>
                        <p>{{ word.pronunciation }}</p>
                    </td>
                </tr>
            </table>
            <table id="flashcards-source">
                <tr v-for="(wordlist, index) in wordsReversed" :key="index">
                    <td v-for="(word, index) in wordlist" :key="index">
                        <p>{{ word.translation1 }}</p>
                        <p>{{ word.translation }}</p>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>


<script>
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { mapGetters } from 'vuex';

const cols = 5;

export default {
    computed: {
        ...mapGetters(['language', 'selectedWords']),
        wordsBroken() {
            const learningWords = this.selectedWords.filter(word => word)
            const arrayOfArrays = [];

            for (let i = 0; i < learningWords.length; i += cols) {
                const chunk = learningWords.slice(i, i + cols);
                arrayOfArrays.push(chunk);
            }
            return arrayOfArrays
        },
        wordsReversed() {
            const learningWords = this.selectedWords.filter(word => word)
            const arrayOfArrays = [];

            for (let i = 0; i < learningWords.length; i += cols) {
                const chunk = learningWords.slice(i, i + cols);
                arrayOfArrays.push(chunk);
            }
            return arrayOfArrays.map(row => {
                const reversedRow = [];
                for (let i = cols - 1; i >= 0; i--) {
                    reversedRow.push(row[i] !== undefined ? row[i] : '');
                }
                return reversedRow;
            });
        }
    },
    methods: {
        generatePDF() {
            const pdf = new jsPDF();
            const targettable = document.querySelector('#flashcards-target');
            const sourcetable = document.querySelector('#flashcards-source');

            html2canvas(targettable)
                .then((canvas) => {
                    const targetImgData = canvas.toDataURL('image/png');
                    const targetImgWidth = 210;
                    const targetImgHeight = (canvas.height * targetImgWidth) / canvas.width;

                    pdf.addImage(targetImgData, 'PNG', 0, 0, targetImgWidth, targetImgHeight);

                    html2canvas(sourcetable)
                        .then((canvas) => {
                            const sourceImgData = canvas.toDataURL('image/png');
                            const sourceImgWidth = 210;
                            const sourceImgHeight = (canvas.height * sourceImgWidth) / canvas.width;
                            pdf.addPage();

                            pdf.addImage(sourceImgData, 'PNG', 0, 0, sourceImgWidth, sourceImgHeight);
                            const today = new Date();
                            const day = String(today.getDate()).padStart(2, '0');
                            const month = String(today.getMonth() + 1).padStart(2, '0');
                            const year = today.getFullYear().toString().slice(-2);
                            pdf.save(`flashcards-${this.language}-${month}-${day}-${year}.pdf`);
                        });
                });
        },
        next() {
            this.$emit('next')
        }
    },
};
</script>

<style scoped>
#flashcards-table {
    width: 100%;
    padding: 20px;
}

#flashcards-table table {
    width: 100%;
    font-size: 15px;
    text-align: center;
}

td {
    border: 2px solid black;
    height: 150px;
    width: 20%;
    overflow: hidden;
    padding: 10px;
}

.flashcards-translation-table {
    page-break-before: always;
}
@media (max-width: 767px){
    button{
        font-size:30px;
    }
}
@media print{
  button{
    display:none;
  }
  #flashcards-table{
    padding:0px;
  }
}
</style>
