<template>
    <div class="wrapper">
        <div>{{ word.rootId }}</div>
        <div>{{ word.pronunciation }}</div>
        <div class="hidden-translation">{{ word.translation }}</div>
        <div class="buttons">
            <TranslatableButton @click="playAudio()" :disabled="loading" text="Play Audio">
            </TranslatableButton>
        </div>
    </div>
</template>

<script>

import TranslatableButton from '@/components/General/TranslatableButton.vue'
export default {
    props: {
        word: Object,
        moveTo: Function,
    },
    components: {
        TranslatableButton
    },
    data() {
        return {
            loading: true
        }
    },
    methods: {
        moveToPosition(position) {
            this.moveTo(position);
        },
        async playAudio() {
            this.loading = true
            this.word.playAudio();
            this.loading = false
        },
    },
    mounted() {
        this.playAudio();
    },
    watch: {
        word(newWord, oldWord) {
            if (newWord !== oldWord) {
                this.playAudio();
            }
        }
    },
};
</script>

<style scoped>
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.wrapper>div {
    margin-bottom: 15px;
    font-size: 48px;
}

.hidden-translation {
    background-color: black;
}

.hidden-translation:hover {
    background-color: white;
}
@media (max-width: 767px){
    .wrapper>div {
        font-size:24px;
    }
}
</style>