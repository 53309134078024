import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/global.css'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import{
  faHouse,
  faGamepad,
  faBookOpen,
  faCog,
  faMicrophone,
  faPause,
  faPlay,
  faPaperPlane,
  faA,
  faArrowsRotate
} from '@fortawesome/free-solid-svg-icons';
library.add(faHouse);
library.add(faGamepad);
library.add(faBookOpen);
library.add(faCog);
library.add(faMicrophone);
library.add(faPause);
library.add(faPlay);
library.add(faPaperPlane);
library.add(faA);
library.add(faArrowsRotate);

createApp(App)
  .use(router)
  .use(store)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app');
