<template>
    <div class="pill-selector">
        <div class="option-container">
            <div class="highlight" :style="highlightStyle"></div>
            <div v-for="(option, index) in options" :key="index" class="option"
                :class="{ 'selected': selectedOption === option.value }" @click="selectOption(option.value)">
                {{ option.text }}
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        options: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            selectedOption: this.options[0].value,
            highlightStyle: {},
        };
    },
    methods: {
        selectOption(option) {
            this.selectedOption = option;
            const selectedIndex = this.options.findIndex(obj => obj.value == option);
            const space = 5;
            const highlightWidth = 100 / this.options.length - space;
            const left = `${highlightWidth * selectedIndex + space / 2 + (selectedIndex * space)}%`;
            this.highlightStyle = {
                width: `${highlightWidth}%`,
                left,
            };
            this.$emit('select', option)
        },
    },
    mounted() {
        const selectedIndex = this.options.findIndex(obj => obj.value == this.selectedOption);
        const space = 5;
        const highlightWidth = 100 / this.options.length - space;
        const left = `${highlightWidth * selectedIndex + space / 2 + (selectedIndex * space)}%`;
        this.highlightStyle = {
            width: `${highlightWidth}%`,
            left,
        };
    },
};
</script>

<style scoped>
.pill-selector {
    font-family: var(--main-font);
    padding: 5px;
    background-color: var(--interactive);
    border-radius: 20px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.option-container {
    display: flex;
    position: relative;
    overflow: hidden;
}

.option {
    flex: 1;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    color: var(--interactive-text);
    z-index: 2;
    font-size: medium;
}

.option.selected {
    color: var(--interactive-highlight-text);
}

.highlight {
    position: absolute;
    bottom: 0;
    height: 80%;
    top: 10%;
    background-color: var(--interactive-highlight);
    transition: all 0.3s;
    z-index: 1;
    border-radius: 25px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}
@media (max-width: 767px){
    .option{
        font-size:12px;
    }
}
</style>