<template>
  <div class='wrapper'>
    <div class="all-indicator-wrapper">
      <div v-for="level in uninitiatedLevels" :key="level" class="uninitiated-indicator"
        :style='{ "backgroundColor": indicatorData[level].color}'>
      </div>
      <div class='level-indicator-wrapper'>
        <div v-for="(count, level) in levelCounts" :key="level"
          :style='{ "backgroundColor": indicatorData[level].color, "width": ((100 * count / total)) + "%" , "color":  indicatorData[level].textColor}'>
          {{indicatorData[level].display}}
        </div>
      </div>
      <div v-for="level in finishedLevels" :key="level" class="uninitiated-indicator"
        :style='{ "backgroundColor": indicatorData[level].color}'>
      </div>
    </div>

    <WarmupCell v-if="currentWord.level==0" :word="currentWord"></WarmupCell>
    <ShadowCell v-if="currentWord.level==1" :word="currentWord"></ShadowCell>
    <WriteCell v-if="currentWord.level==2" :word="currentWord"></WriteCell>
    <WritePracticeCell v-if="currentWord.level==3" :word="currentWord"></WritePracticeCell>

    <div class='buttons'>
      <TranslatableButton @click="evaluateWord('bad')" :disabled="loading" text="Bad" />
      <TranslatableButton @click="evaluateWord('good')" :disabled="loading" text="Okay" />
      <TranslatableButton @click="evaluateWord('perfect')" :disabled="loading" text="Perfect" />
    </div>
  </div>
</template>

<script>
import ShadowCell from '@/components/LessonWorkflow/Flashcards/ShadowCell.vue'
import WarmupCell from '@/components/LessonWorkflow/Flashcards/WarmupCell.vue'
import WriteCell from '@/components/LessonWorkflow/Flashcards/WriteCell.vue'
import WritePracticeCell from '@/components/LessonWorkflow/Flashcards/WritePracticeCell.vue'
import TranslatableButton from '@/components/General/TranslatableButton.vue'
import WordObject from '@/components/LessonWorkflow/Flashcards/WordObject.js'


export default {
  name: 'FlashcardManager',
  components: {
    TranslatableButton,
      WarmupCell,
      WriteCell,
      ShadowCell,
      WritePracticeCell,
  },
  data() {
    return {
      learningWords: this.$store.state.selectedWords.map(word => new WordObject(word))
        .map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value),
      indicatorData:[
        {'color': '#FFFFFF', 'textColor': '#000000', 'display': 'Warmup'},
        {'color': '#BBBBBB', 'textColor': '#000000',  'display': 'Listen'},
        {'color': '#999999', 'textColor': '#000000',  'display': 'Write'},
        {'color': '#777777', 'textColor': '#000000',  'display': 'Speak'},
        {'color': '#000000', 'textColor': '#FFFFFF',  'display': 'Done'},
      ],
      loading: false
    };
  },
  computed: {
    currentWord() {
      return this.learningWords[0];
    },
    levelCounts() {
      const counts = {};
      this.learningWords.forEach(word => {
        counts[word.level] = (counts[word.level] || 0) + 1;
      });
      return counts;
    },
    uninitiatedLevels(){
      let uninitiated = []
      for(let a=0;a<this.indicatorData.length;a++){
        if(!this.learningWords.some(obj=>obj.level>=a))
          uninitiated.push(a)
      }
      uninitiated = uninitiated.reverse()
      return uninitiated
    },
    finishedLevels(){
      let finished = []
      for(let a=0;a<this.indicatorData.length;a++){
        if(!this.learningWords.some(obj=>obj.level<=a))
        finished.push(a)
      }
      finished = finished.reverse()
      return finished
    },
    total() {
      return this.learningWords.length
    }
  },
  methods: {
    evaluateWord(quality) {
      const word = this.learningWords.shift();
      if (quality === 'bad') {
        this.learningWords.splice(3, 0, word); // Moves the word to the 4th position
      } else if (quality === 'good') {
        const middleIndex = Math.floor(this.learningWords.length / 2);
        this.learningWords.splice(middleIndex, 0, word);
      } else if (quality === 'perfect') {
        if (word.level < this.indicatorData.length-1) {
          word.level++; // Increase the level
        }
        this.learningWords.push(word); // Moves the word to the last position
      }
      if(!this.learningWords.some(obj=>obj.level<this.indicatorData.length-1)){
        this.$emit('next')
      }
      else if(this.learningWords[0].level>=this.indicatorData.length-1){
        this.evaluateWord('perfect')
      }

    }
  }
}
</script>
<style scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.buttons {
  display: flex;
}
.all-indicator-wrapper{
  width:100%;
  display:flex;
  align-items: center;
  justify-content: center;
}
.uninitiated-indicator{
  width:10px;
  height:20px;
  border:1px solid black;
}
.level-indicator-wrapper {
  width: 80%;
  height: 20px;
  border: 1px solid black;
  display: flex;
  flex-direction: row-reverse;
}

.level-indicator-wrapper div {
  height: 20px;
  box-sizing: border-box;
  overflow:hidden;
  display:flex;
  justify-content: center;
}
@media (max-width: 767px){
  .buttons{
    width:100%;
    justify-content:space-around;
  }
  .buttons>div{
    width:30%;
  }
}
</style>
