<template>
    <div class="wrapper">
        <div class="chat">
            <div>
                <div class="messages">
                    <div class="spacer"></div>
                    <div v-for="message in messages" :key="message.id" class="message">
                        <div v-if="message.role == 'user'" class="user">
                            {{ message.content }}
                        </div>
                        <div v-if="message.role == 'assistant'" class="assistant">
                            <img :src="chatbotUrl" />
                            <BreakdownText :text="message.roots" @updateWord="updateWord"></BreakdownText>
                            <button @click="playMessage(message.id)"><font-awesome-icon icon="play"></font-awesome-icon></button>
                        </div>
                    </div>
                    <div v-if="error">{{ error }}</div>
                    <div v-if="loading">Loading...</div>
                </div>
                <div class="input-group">
                    <TypableTextarea @setText='setInput' ref="textarea"></TypableTextarea>
                    <TranslatableButton @click="sendChat" :disabled="loading" text="Send" />
                </div>
            </div>
        </div>
        <div class="tools">
            <PillSelector :options="[{ text: 'Roots', value: 'roots' }, { text: 'Corrections', value: 'corrections' }]"
                class="pill" @select="(newState) => toolstate = newState">
            </PillSelector>
            <div class="current-tool">
                <div v-if="toolstate == 'roots'">
                    <RootsList :text="allRoots" @updateWord="changeWord" @ignoreWord="ignoreWord" />
                </div>
                <div v-if="toolstate == 'corrections'">
                    <div class="correction" v-for="(correction, index) in corrections" :key="index">
                        {{ correction }}
                    </div>
                </div>
            </div>
            <TranslatableButton @click="goToSelection" text="Selection" />
        </div>
    </div>
</template>

<script>
import apiClient from '@/api';
import router from '@/router';
import { mapMutations } from 'vuex';
import TranslatableButton from '@/components/General/TranslatableButton.vue'
import TypableTextarea from '@/components/General/TypableTextarea.vue'
import BreakdownText from '@/components/General/BreakdownText.vue';
import RootsList from '@/components/General/RootsList/RootsList.vue'
import PillSelector from '@/components/General/PillSelector.vue'

export default {
    props: {
        chatbotId: Number
    },
    components: {
        TranslatableButton,
        TypableTextarea,
        BreakdownText,
        RootsList,
        PillSelector
    },
    data() {
        return {
            chatbot: {},
            messages: [],
            userInput: '',
            error: '',
            loading: false,
            corrections: [],
            toolstate: 'roots',
        };
    },
    mounted() {
        this.fetchChatbot();
    },
    computed: {
        allRoots() {
            return this.messages.reduce((acc, message) => {
                // Assuming 'roots' is an array in each message
                if (message.roots && message.roots.length) {
                    acc.push(...message.roots);
                }
                return acc;
            }, []);
        },
        chatbotUrl() {
            return process.env.VUE_APP_API_URL + "/" + this.chatbot.imagePath
        }
    },
    methods: {
        ...mapMutations(['addWordsToSeenLearning']),
        fetchChatbot() {
            this.loading = true;
            apiClient.get(`/api/Chatbot/${this.chatbotId}`)
                .then(response => {
                    this.chatbot = response.data;
                    this.initialChat();
                })
                .catch(error => {
                    this.error = 'Failed to load chatbot: ' + (error.response.data.message || error.message);
                    this.loading = false;
                });
        },
        async checkSentence() {
            try {
                const response = await apiClient.post('/api/RootAnalysis/GetCorrection', {
                    languageId: this.$store.state.language,
                    text: this.userInput,
                });
                if (response.status === 200) {
                    this.corrections.push(response.data.correction)
                    console.log(this.corrections)
                }
            } catch (error) {
                this.error = error
                console.error('Error updating word:', error);
            }
        },
        initialChat() {
            apiClient.post('/api/Chatbot/GetInitialChatMessage/' + this.$store.state.language + '/1', { content: this.chatbot.chatbotText, role: "user" })
                .then(response => {
                    console.log(response)
                    this.messages.push({ content: response.data.content, role: "assistant", roots: response.data.roots, id: this.messages.length });
                    this.loading = false
                })
                .catch(error => {
                    this.error = 'Failed to send message: ' + (error.response.data.message || error.message);
                });
        },
        setInput(input) {
            this.userInput = input
        },
        sendChat() {
            this.loading = true
            this.checkSentence()
            this.messages.push({ content: this.userInput, role: 'user', roots: [], id: this.messages.length })
            let sending_messages = this.messages.map(message => { return { content: message.content, role: message.role } })
            apiClient.post('/api/Chatbot/GetChatMessage/' + this.$store.state.language + '/1', sending_messages)
                .then(response => {
                    this.userInput = '';
                    this.messages.push({ content: response.data.content, role: "assistant", roots: response.data.roots, id: this.messages.length });
                    this.loading = false
                    this.$refs.textarea.clear()
                })
                .catch(error => {
                    console.log(error)
                    this.error = 'Failed to send message: ' + (error.response?.data.message || error.message);
                    this.loading = false
                });
        },
        changeWord(rootId, newLevel) {
            console.log(this.messages)
            this.messages.forEach(message => {
                // Assuming 'roots' is an array of objects and each root object has 'rootId' and 'level'
                message.roots.forEach(root => {
                    if (root.root === rootId) {
                        root.level = newLevel;
                    }
                });
            });
        },
        updateWord(rootId, newWord) {
      console.log(rootId, newWord)
            this.messages.forEach(message => {
                message.roots = message.roots.map(word => {
                    if (word.root === rootId) {
                        return { ...word, ...newWord };
                    }
                    return word;
                });
            });
        },
        ignoreWord(rootId) {
            this.messages.forEach(message => {
                // Assuming 'roots' is an array of objects and each root object has 'rootId' and 'level'
                message.roots.forEach(root => {
                    if (root.root === rootId) {

                        root.dontshow = true;
                    }
                });
            });
        },
        getUniqueRootObjects(messages) {
            const uniqueRootsMap = new Map();

            // Iterate over each message and then each root in the message
            messages.forEach(message => {
                message.roots.forEach(root => {
                    // Use the root ID as a key to ensure uniqueness
                    if (!uniqueRootsMap.has(root.root)) {
                        uniqueRootsMap.set(root.root, root);
                    }
                });
            });

            // Convert the values of the map back to an array
            return Array.from(uniqueRootsMap.values());
        },
        async goToSelection() {
            let inListWords = []
            let uniqueObjects = this.getUniqueRootObjects(this.messages)
            for (let rootWord of uniqueObjects.filter(word => word.level == 0)) {
                if (rootWord.translation && rootWord.sentence1) {
                    inListWords.push(rootWord);
                    break;
                }
                const response = await apiClient.get(`/api/RootAnalysis/GetWordDetail?root=${rootWord.root}&languageId=${this.$store.state.language}`);
                response.data.learning = false
                inListWords.push(response.data);
            }
            this.addWordsToSeenLearning(inListWords)
            router.push('selection')
        },
        async playMessage(id){
            for(let message of this.messages){
                if(message.id==id){
                    if(message.audio){
                        message.audio.play()
                    }
                    else{
                        console.log(message.content)
                        const response = await fetch(process.env.VUE_APP_API_URL + '/api/RootAnalysis/SynthesizeText', {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify({
                                text: message.content,
                                languageId: this.$store.state.language
                            }),
                        });
                        const blob = await response.blob();
                        const audioUrl = URL.createObjectURL(blob);
                        const audio = new Audio(audioUrl);
                        message.audio=audio;
                        message.audio.play()
                    }
                }
            }
        }
    }
}
</script>

<style scoped>
.wrapper {
    width: 100%;
    height: 90%;
    display: flex;
}

.wrapper>div {
    flex: 1;
}

.chat {
    padding: 20px;
    border-right: 1px solid black;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: end;
}

.message>div {
    width: 80%;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 5px;
}

.textarea textarea {
    width: 100px;
}

.user {
    background-color: aqua;
    margin-left: auto;
    border-bottom-right-radius: 0px !important;
}

.assistant {
    background-color: #AAA;
    border-bottom-left-radius: 0px !important;
    display: flex;
}

.assistant img {
    width: 75px;
    height: 75px;
    border-radius: 50px;
    margin-right: 20px;
}

.messages {
    height: 80%;
    overflow-y: scroll;
}

.tools {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.current-tool {
    width: 100%;
    overflow-y: scroll;
}

.pill {
    width: 50%;
    margin: 20px;
}

.correction {
    padding: 20px;
    border-bottom: 1px solid black;
}

@media (max-width: 767px) {
    .wrapper {
        flex-direction: column;
        height: 90%;
        overflow-y: scroll;
        gap: 100px;
    }

    .wrapper>div {
        flex: auto;
    }

    .current-tool,
    .messages {
        height: auto;
        overflow-y: auto;
    }

    .assistant img {
        display: none;
    }

    .tools {
        border-top: 1px solid black
    }

    .chat {
        height: auto;
    }
}
</style>
