<template>
  <div class="wrapper">
    <div class="hidden-translation">{{ word.rootId }}</div>
    <div class="hidden-translation">{{ word.currentSentenceText }}</div>
    <div class="hidden-translation">{{ word.translation }}</div>
    <div class="hidden-translation">{{ word.currentTranslationText }}</div>
    <div class="buttons">
      <TranslatableButton @click="playAudio()" text="Play Audio"/>
    </div>
  </div>
</template>

<script>

import TranslatableButton from '@/components/General/TranslatableButton.vue'
export default {
  props: {
    word: Object,
  },
  components:{
    TranslatableButton
  },
  data() {
    return {
    }
  },
  methods: {
    playAudio() {
      this.word.playCurrentSentence();
    },
  },
  mounted() {
    this.word.newSentence();
  },
  watch: {
    word(newWord, oldWord) {
      if (newWord !== oldWord) {
        this.word.newSentence();
      }
    }
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.wrapper>div {
  margin-bottom: 15px;
  font-size: 48px;
}

.hidden-translation {
  background-color: black;
}

.hidden-translation:hover {
  background-color: white;
}
@media (max-width: 767px){
    .wrapper>div {
        font-size:24px;
    }
}
</style>
