<template>
  <MainNavigation>
    <div class="select-wrapper">
        <div class="word-select">
            <div class="words-list">
              <div>Learning words seen today</div>
                <div v-for="word in unselectedWords" :key="word.root" class="word-item">
                    <span>{{ word.rootId }}</span>
                    <span>{{ word.translation }}</span>
                    <TranslatableButton @click="fetchWordDetails(word.rootId)" text="Detail"
                        class="detailsButton"></TranslatableButton>
                    <TranslatableButton @click="moveToStudying(word.rootId)" text="Study"
                        class="detailsButton"></TranslatableButton>
                </div>
            </div>
            <div class="studying-words">
              <div>Selected Words</div>
                <div v-for="word in selectedWords" :key="word.root" class="word-item">
                    <span>{{ word.rootId }}</span>
                    <span>{{ word.translation }}</span>
                    <TranslatableButton @click="fetchWordDetails(word.rootId)" text="Detail"
                        class="detailsButton"></TranslatableButton>
                    <TranslatableButton @click="moveToUnselected(word.rootId)" text="Unselect"
                        class="detailsButton"></TranslatableButton>
                </div>
            </div>
            <div class="word-details">
                <div v-if="selectedWordDetails">
                    <h3>{{ selectedWordDetails.root }}</h3>
                    <p>Translation: {{ selectedWordDetails.translation }}</p>
                    <div v-for="index in 3" :key="index">
                        <p>Sentence {{ index }}: {{ selectedWordDetails['sentence' + index] }}</p>
                        <p>Translation: {{ selectedWordDetails['translation' + index] }}</p>
                    </div>
                    <input v-model="newTranslation" placeholder="Enter New Translation" />
                    <button @click="changeTranslation">Change Translation</button>
                </div>
                <TranslatableButton @click="next" text="Create Lesson" />
            </div>
        </div>
    </div>
  </MainNavigation>
</template>

<script>
import MainNavigation from '@/components/General/MainNavigation.vue'
import apiClient from '@/api';
import TranslatableButton from '@/components/General/TranslatableButton.vue'
import { mapMutations, mapGetters } from 'vuex';
import router from '@/router';
// vậy
// như
// chia sẻ
// trao đổi
// kiến thức
// nhau
// đúng
// tại
// khác biệt
// quốc gia
// khác
export default {
    components: {
        TranslatableButton: TranslatableButton,
        MainNavigation
    },
    data() {
        return {
            studyingWords: [],
            selectedWordDetails: null,
            newTranslation: ""
        };
    },
    computed: {
        ...mapGetters(['selectedWords', 'seenLearningWords']),
        unselectedWords() {
            return this.seenLearningWords.filter(word => !this.selectedWords.some(selectedWord => selectedWord.rootId === word.rootId))
        },
    },
    methods: {
        ...mapMutations(['addWordToSelection', 'removeWordFromSelection']),
        getWordByRoot(root) {
            return this.seenLearningWords[this.seenLearningWords.findIndex(item => item.rootId == root)]
        },
        async fetchWordDetails(root) {
            this.selectedWordDetails = this.getWordByRoot(root)
        },
        moveToStudying(root) {
            this.addWordToSelection(this.getWordByRoot(root))
        },
        moveToUnselected(root) {
            this.removeWordFromSelection(root)
        },
        async changeTranslation() {
            try {
                const response = await apiClient.post('/api/RootAnalysis/ChangeTranslation', {
                    rootId: this.selectedWordDetails.rootId,
                    languageId: this.$store.state.language,
                    newTranslation: this.newTranslation,
                });
                if (response.status === 200) {
                    this.removeWordFromSelection(this.selectedWordDetails.rootId)
                    let word = this.getWordByRoot(this.selectedWordDetails.rootId)
                    word.translation = this.newTranslation
                    this.addWordToSelection(word)
                    this.selectedWordDetails=null
                }
            } catch (error) {
                this.loading = false
                console.error('Error updating word:', error);
            }
        },
        next() {
          router.push('lesson')
        }
    },
};
</script>

<style scoped>
.select-wrapper {
    background-color: white;
    margin: 20px;
    height: 90%;
    border-radius: 15px;
}

.word-select {
    display: flex;
    justify-content: space-between;
    padding: 30px;
    height: 90%;
}

.words-list,
.word-details,
.studying-words {
    flex: 1;
    border: 1px solid black;
    border-radius: 15px;
    padding: 30px;
    overflow-y: scroll;
}

.detailsButton {
    width: 90px;
}

.studying-words {
    margin: 0 30px 0 30px;
}

.word-item {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}
@media (max-width: 767px){
    .word-select{
        flex-direction:column;
        overflow-y:scroll;
    }
    
    .studying-words {
        margin: 0;
    }
}
</style>
