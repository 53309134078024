<template>
    <div>
        <div v-if="!initializing" class="wrapper">
            <div class="progressWrapper">
                <div class="progress-bar">
                    <div class="progress" :style="{width:`${progress}%`}">{{this.oglength-this.words.length}}</div>
                </div>
            </div>
            <div class="content">
                <div>{{ currentWord.translation }}</div>
                <TypableTextarea @setText='setInput' ref='textarea'></TypableTextarea>
                <div v-if="checking == true && !currentWord.pronunciation">
                    <div>ROTO</div>
                    <span v-for="(char, index) in currentWord.rootId.split('')" :key="index"
                        :class="{ 'incorrect': char !== userInput[index] }">{{ char }}</span>
                </div>
                <div v-if="checking == true && currentWord.pronunciation">
                    <div>PRONUNCE</div>
                    <span v-for="(char, index) in currentWord.pronunciation.split('')" :key="index"
                        :class="{ 'incorrect': char !== userInput[index] }">{{ char }}</span>
                </div>
                <div v-if="checking==false">
                    <TranslatableButton @click="checking=true" 
                        :disabled="loading" 
                        text="Check" />
                </div>
                <div v-if="checking==true">{{currentWord.mnemonics}}</div>
                <div v-if="checking==true" class="buttons">
                    <TranslatableButton @click="wrong()" 
                        :disabled="loading" 
                        text="Wrong" />
                    <TranslatableButton @click="right()" 
                        :disabled="loading" 
                        text="Right" />
                </div>
            </div>
        </div>
        <div v-if="initializing">
            Loading
        </div>
    </div>
</template>
  
  <script>
  import apiClient from '@/api';
  import { mapGetters } from 'vuex';
  import WordObject from '@/components/Lesson/WordObject.js'
  import TranslatableButton from '@/components/General/TranslatableButton.vue'
import TypableTextarea from '@/components/General/TypableTextarea.vue'
  
  export default {
    props: {
        lesson: Object,
    },
      components: {
        TranslatableButton,
        TypableTextarea
      },
      data() {
        return{
            words:this.lesson.lessonRoots.map(word => new WordObject(word))
                .map(value => ({ value, sort: Math.random() }))
                .sort((a, b) => a.sort - b.sort)
                .map(({ value }) => value),
            currentWord: {rootId:'NONE'},
            initializing:true,
            loading:true,
            oglength: this.lesson.lessonRoots.length,
            pageState:0,
            userInput:'',
            checking:false
        }
      },
      computed: {
        ...mapGetters(['languageName', 'language']),
        progress(){
            return 100-(100*this.words.length/this.oglength)
        }
      },
      methods: {
        setInput(input) {
            this.userInput = input
        },
        async right(){
            this.words.shift();
            this.currentWord = this.words[0]
            if(!this.currentWord){
                const response = await apiClient.post('/api/RootAnalysis/UpdateLesson', {
                    lessonId: this.lesson.lessonId,
                    action: "UsedInWrite"
                });
                if (response.status === 200) {
                    this.$emit('finishCard')
                }
            }
            this.$refs.textarea.clear()
            this.checking=false
        },
        wrong(){
            const word = this.words.shift();
            if(this.words.length>4){
                this.words.splice(3, 0, word); // Moves the word to the 4th position
            }
            else{
                this.words.push(word)
            }
            this.currentWord = this.words[0]
            this.$refs.textarea.clear()
            this.checking=false
        },
      },
      mounted(){
        this.currentWord = this.words[0]
        console.log(this.currentWord)
        this.initializing=false
        this.loading=false
      }
  }
  </script>
  
  <style scoped>
  .wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      height:100%;
      width:100%;
  }
  .problem{
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: space-between;
  }
  .content{
    display:flex;
    justify-content:space-around;
    align-items: center;
    flex-direction: column;
    width:100%;
  }
  .progressWrapper{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
  }
  
  .wrapper>div {
      margin-bottom: 15px;
      font-size: 48px;
  }
  
  .hidden-translation {
      background-color: black;
  }
  
  .hidden-translation:hover {
      background-color: white;
  }
  .rightwrongbuttons, .buttons{
    display:flex;
  }
  .progress-bar{
    width:80%;
    height:25px;
    background-color:red;
  }
  .progress{
    height:100%;
    background-color:green;
    font-size:20px;
    text-align:center;
  }
  .mnemonicHelp{
    font-size:20px;
  }
  .correction{
    width:50%;
    font-size:20px;
  }
  .buttons{
    display:flex;
  }

.incorrect {
    color: red;
}
  @media (max-width: 767px){
      .wrapper>div {
          font-size:24px;
      }
  }
  </style>
  