<template>
  <div id='all-wrapper'>
    <div class="top-nav">
      <img class="logo-pic1" :src="webpageLogo" @click="checked = !checked">
      <div class="top-btn" style="visibility:hidden;">
        <span class='settings-button'><font-awesome-icon icon="cog"></font-awesome-icon></span>
      </div>

      <div class="top-btn">
        <span class='settings-button'
          @click='routeTo("selection")'
          >
          Selected: {{selectedCount}}
        </span>
        <span class='settings-button'
          @click='routeTo("settings")'
          >
          <font-awesome-icon icon="cog"></font-awesome-icon>
        </span>
      </div>
    </div>
    <div class="nav-container">

      <div class="navigation" :class="{ selected: checked }" v-on:click.stop @mouseleave="displayingFlags=false">
        <div class='main-navigation'>
          <div
            v-for='(route, index) in routes'
            class="nav-item"
            :key='index'
            @click="routeTo(route.url)"
            :class="{ 'active': route.routes.includes(routeName) }"
          >
            <font-awesome-icon :icon="route.icon" />
            <span class='nav-label'>{{ route.name }}</span>
          </div>

        </div>

        <div class='flag-navigation' @click='displayingFlags=!displayingFlags'>
          <img :src="flagUrl" />
          <div class='all-flags' v-if='displayingFlags'>
            <div v-for='lang in allLangs' :key="lang.id" class='lang-option' @click='setLangaugeAtStore(lang.id);checked=false'>
              <img :src="lang.flag_url" />
              <span class='nav-label'>{{ lang.name }}</span>
            </div>
          </div>
        </div>

      </div>
      <div class="nav-contents">
        <slot />
      </div>
    </div>

  </div>
</template>

<script>
import router from '@/router';
import img2 from '@/assets/logo_topLeft.png'
import languagesData from '@/languages.json';
import { mapMutations, mapGetters } from 'vuex';
import apiClient from '@/api';

export default {
  name: 'MainNavigation',
  props: {
    msg: String,
  },
  data() {
    return {
      checked: false,
      webpageLogo: img2,
      displayingFlags:false,
    }
  },
  computed: {
    ...mapGetters(['language', 'selectedWords']),
    routes(){
      return [
        {
          'name': 'Home',
          'icon': 'house',
          'routes': ['home'],
          'url': '/'
        },
        {
          'name': 'Lesson',
          'icon': 'gamepad',
          'routes': ['lesson'],
          'url': '/lesson'
        },
        {
          'name': 'Lessons',
          'icon': 'book-open',
          'routes': ['/lessons'],
          'url': '/lessons'
        },
        {
          'name': 'Shadow Practice',
          'icon': 'a',
          'routes': ['/shadow-practice'],
          'url': '/shadow-practice'
        },
        {
          'name': 'Translation Tool',
          'icon': 'arrows-rotate',
          'routes': ['/translation-tool'],
          'url': '/translation-tool'
        },
      ]
    },
    routeName() {
      return this.$route.name
    },
    flagUrl(){
      if(this.language)
        return "/flags/"+this.language+".png"
      return "/flags/worldmap.png"
    },
    allLangs(){
      return languagesData
    },
    selectedCount(){
      return this.selectedWords.length
    },
  },
  methods: {
    ...mapMutations(['setLanguage', 'setSelection']),
    setLangaugeAtStore(id){
      this.setLanguage(id);
      this.putLessonIntoSelected()
    },
    routeTo(url) {
      router.push(url)
    },
    putLessonIntoSelected() {
      console.log("Put")
        if (!this.language) {
            console.log("Select a langauge")
            return
        }
        apiClient.get('/api/RootAnalysis/Lesson?UserId=1&LanguageId=' + this.language)
            .then(response => {
              console.log(":)")
              console.log(response.data[0].lessonRoots)
              this.setSelection(response.data[0].lessonRoots.map(root=>root.root))
            })
            .catch(error => {
              console.log(":(")
              console.log(error)
            });
    }
  },
  components: {
  },
  mounted(){
    //Get the most recent lesson and put the words in the selected
    this.putLessonIntoSelected()
  }
}
</script>

<style scoped>
p{
  width:100%;
  text-align:center;
}
#all-wrapper {
  background-color:var(--action-2-text);

  height: 100%;
}


.logo-pic1 {
  background-color: var(--top-nav-background);

  position: absolute;
  width: 100px;
  height: 100px;

  top: 0;
  left: 0;
  padding: 10px;
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: 20px;
}


.top-nav {
  background-color: var(--top-nav-background);
  color: var(--top-nav-text);

  display:flex;
  justify-content: space-between;
  align-items:center;
  height:5%;
}


.top-btn {
  font-family:var(--heading-font);
  color: var(--top-nav-text);
  border: 0;
  display:flex;
  align-items:center;
  justify-content: space-between;
  padding-right:3%;
  font-size: xx-large;
}

.nav-container {
  width: 100%;
  margin: 0;
  height: 95%;

  display: flex;
}


.nav-contents{
  flex-grow:1;
  min-width:0;
  height:100%;
  padding:40px 20px 40px 20px;
  box-sizing: border-box;
}


.main-navigation, .flag-navigation {
  margin-top: 100px;
  width:100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.navigation {
  background-color: var(--left-nav-background);
  align-items: center;
  justify-content: space-between;

  padding:15px;

  display: flex;
  flex-direction: column;
}


.action, .secondaryAction{
  width:100%;
}

.selection-dropdown{
  background-color:var(--interactive);
  color:var(--interactive-text);
  font-family:var(--main-font);
  font-size:15px;
  padding:5px;
  margin:15px;
}
.selection-dropdown.disabled{
  background-color:var(--action-1-disabled);
}

.selection{
  position:absolute;
}
.selection div{
  width:100%;
  background-color:var(--interactive);
  border: 1px solid var(--interactive-text);
  color:var(--interactive-text);
  font-family:var(--main-font);
  font-size:large;
  padding:5px;
}

.settings-button{
  color:white;
  display:flex;
  padding:5px;
}
.settings-button:hover{
  background-color:var(--left-nav-background);
}
.settings-button.active{
  background-color:var(--left-nav-background);
}


.nav-item{
  display:flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  color: black;
  border: none;
  padding: 5px;
  margin-bottom:15px;
  border-radius: 5px;
  transition: all 0.3s ease;
  width:30px;
}

.navigation{
  transition: all 0.3s ease;
}

.nav-item svg{
  width:30px;
  height:30px;
  display:block;
  vertical-align:middle;
  font-size:30px;
}

.nav-label{
  width:0px;
  overflow:hidden;
  vertical-align: middle;
  margin-left: 10px; /* Adjust the spacing between icon and text */
  transition: all 0.3s ease;
  text-align:right;
  font-size:15px;
  font-family:var(--heading-font);
  white-space:nowrap
}

.navigation:hover .nav-item{
  width:200px;
}

.navigation:hover .nav-label{
  width:150px;
}

.nav-item.active, .nav-item:hover{
  background-color:var(--top-nav-background);
}

.flag-navigation{
  width:100%;
  border-radius:5px;
  transition:0.3s;
  position:relative;
}
.all-flags{
  position:absolute;
  bottom:100%;
  width:100%;
}
.lang-option{
  width:100%;
  border-radius:5px;
  margin-bottom:15px;
  height:60px;
  display:flex;
  justify-content: space-between;
  align-items: center;
  font-family:var(--heading-font);
  padding-right:5px;
  transition:all 0.3s ease;
}
.lang-option:hover{
  background-color:var(--top-nav-background);
}
.navigation:hover .flag-navigation{
  background-color:var(--top-nav-background);
}
.navigation:hover .flag-navigation:hover{
  background-color:var(--action-1-hover);
}

.flag-navigation img{
  width:60px;
  margin:5px;
  margin-right:10px;
}
navigation:hover img{
  width:200px;
}

@media only screen and (max-width:767px) {

  .logo-pic1 {
    width: 50px;
    height: 50px;
    border-bottom-right-radius: 20;
    border-bottom-left-radius: 0;
  }

  .top-btn img{
    width:40px;
  }

  .nav-container {
    flex-direction: column;
  }

  .top-nav {
    height: auto;
    z-index: 4;
    margin: 0;
    padding: 0;
  }

  .main-navigation, .flag-navigation{
    margin-top:30px;
    width:80%;
  }

  .side-btns{
    width:100%;
  }

  .navigation {
    z-index: 3;
    width: 100%;
    height:80%;
    padding-left:0px;
    padding-right:0px;
    position: absolute;
    transition: transform 0.4s;
    transform: translateY(-110%);
  }

  .navigation.selected {
    transform: translateY(0%);
  }
  .all-flags{
    display:flex;
    flex-wrap:wrap;
    justify-content: space-around;
    width:100%;
  }
  .lang-option{
    width:25%;
  }
  .nav-item{
    width:80%;
  }
  .nav-label{
    display:none;
  }
  .nav-contents{
    padding:0px;
  }
}
@media print{
  .top-nav, .navigation{
    display:none;
  }
  .nav-contents{
    padding:0px;
  }
}
</style>
