<template>
  <div class="results-wrapper">
    <div class="left">
      RESULTS:
      <RootsList :text="workflowData.rawResults" @updateWord="changeWord" @ignoreWord="ignoreWord" />
    </div>
    <div class="right">
      <BreakdownText :text="workflowData.rawResults" @updateWord="updateWord" />
      <TranslatableButton @click="nextPage" class="next-button" text="Next">
      </TranslatableButton>
    </div>
  </div>
</template>

<script>
import BreakdownText from '@/components/General/BreakdownText.vue';
import RootsList from '@/components/General/RootsList/RootsList.vue'
import apiClient from '@/api';
import TranslatableButton from '@/components/General/TranslatableButton.vue'
import { mapMutations } from 'vuex';
import router from '@/router';

export default {
  props: {
    workflowData: Object
  },
  components: {
    BreakdownText,
    RootsList,
    TranslatableButton
  },
  methods: {
    ...mapMutations(['addWordsToSeenLearning']),
    changeWord(rootId, level) {
      const updatedResults = this.workflowData.rawResults.map(word => {
        if (word.root === rootId) {
          return { ...word, level };
        }
        return word;
      });
      this.$emit('setData', { rawResults: updatedResults })
    },
    updateWord(rootId, newWord) {
      const updatedResults = this.workflowData.rawResults.map(word => {
        if (word.root === rootId) {
          return { ...word, ...newWord };
        }
        return word;
      });
      this.$emit('setData', { rawResults: updatedResults })
    },
    ignoreWord(rootId) {
      const updatedResults = this.workflowData.rawResults.map(word => {
        if (word.root === rootId) {
          return { ...word, dontshow: true };
        }
        return word;
      });
      this.$emit('setData', { rawResults: updatedResults })
    },
    async nextPage() {
      let inListWords = []
      // Use a Set to keep track of unique rootId values
      const uniqueRootIds = new Set();

      // Use the filter method to filter objects with unique rootId values
      const uniqueObjects = this.workflowData.rawResults.filter(obj => {
        if (!uniqueRootIds.has(obj.root)) {
          uniqueRootIds.add(obj.root);
          return true;
        }
        return false;
      });
      for (let rootWord of uniqueObjects.filter(word => word.level == 0)) {
        const response = await apiClient.get(`/api/RootAnalysis/GetWordDetail?root=${rootWord.root}&languageId=${this.$store.state.language}`);
        response.data.learning = false
        inListWords.push(response.data);
      }
      this.addWordsToSeenLearning(inListWords)
      router.push('selection')
    }
  }
};
</script>

<style scoped>
.results-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
}

.results-wrapper>div {
  padding: 30px;
}

.results-wrapper .left {
  flex: 1;
  border-right: 1px solid black;
}

.results-wrapper .right {
  overflow-y: scroll;
  flex: 2;
}

.next-button {
  right: 20px;
  bottom: 20px;
}
</style>
