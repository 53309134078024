<template>
    <div class="options">
        <div v-if="error">{{ error }}</div>
        <TranslatableButton @click="newChatbot" text="New Chatbot" />
        <div v-if="loading">Loading...</div>
    </div>
    <div v-if="!error" class="bots">
        <div v-for="chatbot in chatbots" :key="chatbot.id" class="chatbot" @mouseover="hover = chatbot.id"
            @mouseleave="hover = null" @click="goToUse(chatbot.id)">
            <div class="chatbot-background" :style="getBackgroundStyle(chatbot.imagePath)">
                <div class="chatbot-info">
                    <h3>{{ chatbot.name }}</h3>
                    <p v-if="hover === chatbot.id">{{ chatbot.displayText }}</p>
                    <p v-else>{{ chatbot.displayText.substr(0, 100) }}{{ chatbot.displayText.length > 100 ? '...' : ''
                        }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import apiClient from '@/api';
import { mapState } from 'vuex';
import TranslatableButton from '@/components/General/TranslatableButton.vue';

export default {
    emits: ['create', 'use'],
    components:{
        TranslatableButton
    },
    data() {
        return {
            chatbots: [],
            error: '',
            loading: false,
            hover: 0
        };
    },
    computed: {
        ...mapState({
            language: state => state.language,
        }),
    },
    mounted() {
        this.fetchChatbots();
    },
    methods: {
        newChatbot() {
            this.$emit('create')
        },
        fetchChatbots() {
            this.loading = true;
            if (!this.$store.state.language) {
                this.error = "Please select a language"
                this.loading = false;
                return
            }
            else{
                this.error=""
            }
            apiClient.get('/api/Chatbot?LanguageId=' + this.$store.state.language)
                .then(response => {
                    this.chatbots = response.data;
                    this.loading = false;
                })
                .catch(error => {
                    this.error = 'Failed to load chatbots: ' + (error.response?.data.message || error.message);
                    this.loading = false;
                });
        },
        getBackgroundStyle(imagePath) {
            const url = `${process.env.VUE_APP_API_URL}/${imagePath.replace('\\', '/')}`;
            return {
                backgroundImage: `url('${url}')`
            };
        },
        goToUse(id) {
            this.$emit('use', id)
        }
    },
  watch: {
    language() {
      this.fetchChatbots()
    },
  },
}
</script>

<style scoped>

.bots{
    display:flex;
}

.chatbot {
    margin: 20px;
    overflow: hidden;
    border-radius: 8px;
    position: relative;
    width: 300px;
    height: 200px;
}

.chatbot-background {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: 0.3s;
    background-blend-mode: darken;
}

.chatbot-background:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 100%);
    transition: 0.3s;
}

.chatbot-background:hover:before {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.8) 100%);
}

.chatbot-info {
    color: white;
    padding: 10px;
    text-shadow: 1px 1px 2px black;
    position: relative;
    /* Ensure it's above the gradient */
}

h3,
p {
    margin: 5px 0;
}

@media (max-width: 767px){
    .chatbot {
        margin: 20px;
        overflow: hidden;
        border-radius: 8px;
        position: relative;
        width: 90%;
        height: 200px;
    }
    .options{
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
</style>