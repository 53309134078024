<template>
    <div>
        <div v-if="!initializing" class="wrapper">
            <div class="progressWrapper">
                <div class="progress-bar">
                    <div class="progress" :style="{width:`${progress}%`}">{{this.oglength-this.words.length}}</div>
                </div>
                <TranslatableButton @click="seeSettings=!seeSettings;translation=currentWord.translation;mnemonic=currentWord.mnemonic" 
                    :disabled="loading" 
                    text="Settings" />
            </div>
            <div class="content">
                <div class="problem">
                    <div>{{ currentWord.rootId }}</div>
                    <div>{{ currentWord.pronunciation }}</div>
                    <div class="hidden-translation">{{ currentWord.translation }}</div>
                    <div class="hidden-translation">{{ currentWord.mnemonics }}</div>
                    <div class="buttons">
                        <TranslatableButton @click="playAudio()" :disabled="loading" text="Play Audio">
                        </TranslatableButton>
                    </div>
                    <div class="rightwrongbuttons">
                        <TranslatableButton @click="wrong()" :disabled="loading" text="Wrong" />
                        <TranslatableButton @click="right()" :disabled="loading" text="Right" />
                    </div>
                </div>
                <div v-if="seeSettings">
                    <input v-model="translation" placeholder="Translation" />
                    <TranslatableButton @click="submitTranslation()" :disabled="loading" text="Submit Translation" />
                    <input v-model="mnemonic" placeholder="mnemonic" />
                    <TranslatableButton @click="submitMnemonic()" :disabled="loading" text="Submit Mnemonic" />
                    <TranslatableButton @click="getMnemonicHelp()" :disabled="loading" text="Mnemonic Help" />
                    <div class='mnemonicHelp'>{{ mnemonicHelp }}</div>
                </div>
            </div>
        </div>
        <div v-if="initializing">
            Loading
        </div>
    </div>
</template>
  
  <script>
  import apiClient from '@/api';
  import { mapGetters } from 'vuex';
  import WordObject from '@/components/Lesson/WordObject.js'
  import TranslatableButton from '@/components/General/TranslatableButton.vue'
  
  export default {
    props: {
        lesson: Object,
    },
      components: {
        TranslatableButton
      },
      data() {
        return{
            words:this.lesson.lessonRoots.map(word => new WordObject(word))
                .map(value => ({ value, sort: Math.random() }))
                .sort((a, b) => a.sort - b.sort)
                .map(({ value }) => value),
            currentWord: {rootId:'NONE'},
            initializing:true,
            loading:true,
            seeSettings:false,
            translation:'',
            mnemonic:'',
            oglength: this.lesson.lessonRoots.length,
            mnemonicHelp:''
        }
      },
      computed: {
        ...mapGetters(['languageName', 'language']),
        progress(){
            return 100-(100*this.words.length/this.oglength)
        }
      },
      methods: {
        async playAudio() {
            this.loading = true
            this.currentWord.playAudio();
            this.loading = false
        },
        async submitTranslation() {
            this.loading=true
            try {
                console.log(this.currentWord.rootId,  this.lesson.languageId, this.translation)
                const response = await apiClient.post('/api/RootAnalysis/ChangeTranslation', {
                    rootId: this.currentWord.rootId,
                    languageId: this.lesson.languageId,
                    newTranslation: this.translation,
                });
                if (response.status === 200) {
                    this.words.find(obj => obj.rootId === this.currentWord.rootId).translation=this.translation
                    this.currentWord.translation = this.translation
                    this.$emit("changeWord", this.currentWord.rootId, "translation", this.translation)
                    this.seeSettings=false
                    this.loading=false
                }
            } catch (error) {
                this.loading = false
                console.error('Error updating word:', error);
            }
        },
        async submitMnemonic () {
            this.loading=true
            try {
                const response = await apiClient.post('/api/RootAnalysis/ChangeMnemonic', {
                    rootId: this.currentWord.rootId,
                    languageId: this.lesson.languageId,
                    newTranslation: this.mnemonic,
                });
                if (response.status === 200) {
                    this.words.find(obj => obj.rootId === this.currentWord.rootId).mnemonic=this.mnemonic
                    this.currentWord.mnemonics = this.mnemonic
                    this.seeSettings=false
                    this.loading=false
                    this.$emit("changeWord", this.currentWord.rootId, "mnemonic", this.mnemonic)
                }
            } catch (error) {
                this.loading = false
                console.error('Error updating word:', error);
            }
        },
        async getMnemonicHelp () {
            this.loading=true
            try {
                const response = await apiClient.post('/api/RootAnalysis/GetMnemonic', {
                    text: this.currentWord.rootId,
                    languageId: this.lesson.languageId
                });
                if (response.status === 200) {
                    this.mnemonicHelp=response.data.correction
                    this.loading=false
                }
            } catch (error) {
                this.loading = false
                console.error('Error updating word:', error);
            }
        },
        async right(){
            console.log("Right")
            this.initializing=true
            this.words.shift();
            this.currentWord = this.words[0]
            console.log(this.currentWord)
            if(!this.currentWord){
                this.initializing=true
                console.log("No word")
                const response = await apiClient.post('/api/RootAnalysis/UpdateLesson', {
                    lessonId: this.lesson.lessonId,
                    action: "UsedInFlash"
                });
                if (response.status === 200) {
                    console.log("Status 200")
                    this.$emit('finishCard')
                }
                return
            }
            this.initializing=false
            this.playAudio()
        },
        wrong(){
            const word = this.words.shift();
            if(this.words.length>4){
                this.words.splice(3, 0, word); // Moves the word to the 4th position
            }
            else{
                this.words.push(word)
            }
            console.log(this.words)
            this.currentWord = this.words[0]
            this.playAudio()
        },
      },
      mounted(){
        this.currentWord = this.words[0]
        this.initializing=false
        this.playAudio()
      }
  }
  </script>
  
  <style scoped>
  .wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      height:100%;
      width:100%;
  }
  .content{
    display:flex;
    justify-content:space-around;
    width:100%;
  }
  .progressWrapper{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
  }
  
  .wrapper>div {
      margin-bottom: 15px;
      font-size: 48px;
  }
  
  .hidden-translation {
      background-color: black;
  }
  
  .hidden-translation:hover {
      background-color: white;
  }
  .rightwrongbuttons, .buttons{
    display:flex;
  }
  .progress-bar{
    width:80%;
    height:25px;
    background-color:red;
  }
  .progress{
    height:100%;
    background-color:green;
    font-size:20px;
    text-align:center;
  }
  .mnemonicHelp{
    font-size:20px;
  }
  @media (max-width: 767px){
      .wrapper>div {
          font-size:24px;
      }
  }
  </style>
  