<template>
    <MainNavigation>
        <div class='wrapper'>
            <div class="steps">
                <div v-for="(step, index) in steps" :key="index"
                    :class="{ current: index === currentStep, passed: index < maxStep }" @click="goToStep(index)">
                    {{ step.name }}
                </div>
            </div>
            <component :is="currentComponent" @next="goToNextStep" :key="componentKey" />
        </div>
    </MainNavigation>
</template>

<script>

import MainNavigation from '@/components/General/MainNavigation.vue'
import FlashcardGenerator from '@/components/LessonWorkflow/FlashcardGenerator.vue'
import FlashcardManager from '@/components/LessonWorkflow/Flashcards/FlashcardManager.vue'
import ReadStory from '@/components/LessonWorkflow/ReadStory.vue'

export default {
    components: {
        MainNavigation
    },
    data() {
        return {
            steps: [
                { name: 'Printable', component: FlashcardGenerator },
                { name: 'Flashcard', component: FlashcardManager },
                { name: 'Story', component: ReadStory },
                // Add more steps as needed
            ],
            currentStep: 0,
            maxStep: 0,
            componentKey: 0,
        };
    },
    computed: {
        currentComponent() {
            return this.steps[this.currentStep].component;
        },
    },
    methods: {
        goToStep(index) {
            if (index < this.maxStep) {
                this.currentStep = index;
            }
        },
        goToNextStep() {
            if (this.currentStep < this.steps.length - 1) {
                this.currentStep++;
                if (this.currentStep > this.maxStep) {
                    this.maxStep = this.currentStep;
                }
            }
        },
    },
};
</script>

<style scoped>
.wrapper {
    background-color: white;
    margin: 20px;
    height: 90%;
    border-radius: 15px;
    display: flex;
}

.steps {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: 1px solid black;
}

.steps div {
    height: 30px;
    width: 150px;
    margin: 10px;
    border: 1px solid black;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.current {
    background-color: green;
}

.passed {
    background-color: blue;
    color: white;
}
@media (max-width: 767px){
    .steps{
        display:none;
    }
}
@media .print{
  .wrapper, .steps div{
    margin:none;
    padding:none
  }
}
</style>
