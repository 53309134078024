export default class WordObject {
    constructor(wordObj) {
        // Assign existing properties
        this.rootId = wordObj.root.rootId;
        this.translation = wordObj.root.translation;
        this.pronunciation = wordObj.root.pronunciation;
        this.mnemonics = wordObj.root.mnemonics;
        this.language = wordObj.root.languageId;
        this.finished=false;

        // Add new properties
        this.translationAudio = null;
    }

    async getAudio(text) {
        const response = await fetch(process.env.VUE_APP_API_URL + '/api/RootAnalysis/SynthesizeText', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                text: text,
                languageId: this.language
            }),
        });
        const blob = await response.blob();
        const audioUrl = URL.createObjectURL(blob);
        const audio = new Audio(audioUrl);
        return audio
    }
    //Tôi học về lĩnh vực trí tuệ nhân tạo và lập trình máy tính . Bạn học ngành gì ?
    async playAudio() {
        if (!this.translationAudio) {
            this.translationAudio = await this.getAudio(this.rootId)
        }
        this.translationAudio.play()
    }
}