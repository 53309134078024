<template>
    <MainNavigation>
      <div class="wrapper">
        <h1>Recent Lessons</h1>
        <p v-if="loading">Loading...</p>
        <div class="lesson" v-for="lesson in lessons" :key="lesson.lessonId">
            <div class="words">
                <div class="word" v-for="root in lesson.lessonRoots" :key="root.rootId">
                    {{ root.root.rootId }} ({{root.root.translation}}) <span class="remove" @click="remove(lesson.lessonId, root.rootId)">X</span>
                </div>
            </div>
            <div class="buttons">
              <div>{{ lesson.lessonRoots.length }} words</div>
                <TranslatableButton text="Go to lesson" @click='goToLesson(lesson.lessonId)' :loading="loading"  />
                <div class="completedDisplay">
                  <div :class="`${(lesson.usedInFlash)?'finished':''}`" >FL</div>
                  <div :class="`${(lesson.usedInSound)?'finished':''}`" >SO</div>
                  <div :class="`${(lesson.usedInSay)?'finished':''}`" >SA</div>
                  <div :class="`${(lesson.usedInWrite)?'finished':''}`" >WR</div>
                </div>
            </div>
        </div>
    </div>
    </MainNavigation>
  </template>

  <script>
  import MainNavigation from '@/components/General/MainNavigation.vue'
  import apiClient from '@/api';
  import TranslatableButton from '@/components/General/TranslatableButton.vue'
  import { mapGetters } from 'vuex';

  export default {
      components: {
          TranslatableButton: TranslatableButton,
          MainNavigation,
      },
      data() {
          return {
            lessons:null,
            loading:true
          };
      },
      computed: {
        ...mapGetters(['languageName', 'language']),
      },
      methods: {

        fetchLessons() {
            this.loading = true;
            if (!this.$store.state.language) {
                this.error = "Please select a language"
                this.loading = false;
                return
            }
            else{
                this.error=""
            }
            apiClient.get('/api/RootAnalysis/Lessons?UserId=1&LanguageId=' + this.$store.state.language)
                .then(response => {
                    console.log(response.data)
                    this.lessons = response.data;
                    this.loading = false;
                })
                .catch(error => {
                    this.error = 'Failed to load lessons: ' + (error.response?.data.message || error.message);
                    this.loading = false;
                });
        },
        remove(lessonId, rootId){
          this.loading = true;
          apiClient.get(`/api/RootAnalysis/RemoveFromLesson?UserId=1&LessonId=${lessonId}&rootId=${rootId}`)
              .then(response => {
              console.log(response)
                return this.lessons.map(lesson => {
                  if (lesson.lessonId === lessonId) {
                    lesson.lessonRoots = lesson.lessonRoots.filter(root => root.rootId !== rootId);
                  }
                    this.loading = false;
                  return lesson;
                })
              })
              .catch(error => {
                  this.error = 'Failed to load lessons: ' + (error.response?.data.message || error.message);
                  this.loading = false;
              });
        },
        goToLesson(lessonId){
            this.$router.push({ name: 'lesson', params: { id: lessonId } });
        }
      },
        mounted() {
            this.fetchLessons();
        },
  }
  </script>

  <style scoped>
  .wrapper {
      background-color: white;
      margin: 20px;
      height: 90%;
      border-radius: 15px;
      display:flex;
      justify-content: space-around;
      align-items: center;
      flex-direction:column;
      overflow-x:scroll;
  }
  .lesson{
    width:80%;
    border:1px solid black;
    border-radius:15px;
    background-color:var(--left-nav-background);
    display:flex;
  }
  .words{
    flex:3;
    padding:15px;
    display:flex;
    flex-wrap:wrap;
    border-right:1px solid black;
  }
  .buttons{
    flex:1;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    height:100%;
  }
  .word{
    background-color:white;
    margin:10px;
    border:1px solid black;
    padding:5px;
  }
  .completedDisplay{
    display:flex;
    justify-content:space-between;
    margin-top:15px;
    width:100%;
  }
  .completedDisplay>div{
    padding:5px;
    margin:10px;
    border:1px solid black;
    background-color:grey;
  }
  .completedDisplay .finished{
    background-color:white;
  }
  @media (max-width: 767px) {
    .wrapper {
      flex-direction:column;
    }
  }
  </style>
