<template>
    <div class="letter-stats">
      <table>
        <thead>
          <tr>
            <th>Letter</th>
            <th>Pronunciation</th>
            <th>Count</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(stats, letter) in letterStats" :key="letter">
            <td>{{ letter }}</td>
            <td :style="{ backgroundColor: stats.count >= 5 ? 'black' : 'white' }">{{ stats.pronunciation }}</td>
            <td>{{ stats.count }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  export default {
    props: ['letterStats'],
  };
  </script>
  
  <style scoped>
  .letter-stats table {
    width: 100%;
  }
  .letter-stats td {
    text-align: center;
    padding: 5px;
  }
  .letter-stats td:hover {
    background-color: gray;
  }
  </style>