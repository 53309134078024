<template>
    <div>
        <div class="buttons">
            <TranslatableButton @click="next" text="Next">
            </TranslatableButton>
            <TranslatableButton @click="playAudio" text="Read">
            </TranslatableButton>
            <TranslatableButton @click="downloadAudio" text="Download">
            </TranslatableButton>
        </div>
        <div class="wrapper">
            <div class="content">
                <BreakdownText v-if="!loading" :text="roots" />
                <div v-if="loading">Loading...</div>
            </div>
        </div>
    </div>
</template>

<script>
import apiClient from '@/api';
import TranslatableButton from '@/components/General/TranslatableButton.vue'
import BreakdownText from '@/components/General/BreakdownText.vue';

export default {
    components: {
        TranslatableButton: TranslatableButton,
        BreakdownText,
    },
    data() {
        return {
            story: '',
            roots: [],
            loading: false,
            audio: null,
            learningWords: this.$store.state.selectedWords.map(word => ({ ...word })),
        };
    },
    computed: {
    },
    methods: {
        async createAudio() {
            try {
                const response = await fetch(process.env.VUE_APP_API_URL+'/api/RootAnalysis/SynthesizeText', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        text: this.story,
                        languageId: this.$store.state.language
                    }),
                });
                const blob = await response.blob();
                const audioUrl = URL.createObjectURL(blob);
                const audio = new Audio(audioUrl);
                this.audio = audio
            } catch (err) {
                console.error('Error:', err);
            }
        },
        playAudio() {
            this.audio.play();
        },
        downloadAudio() {
            const link = document.createElement('a');
            link.href = this.audio.src; // Use the blob URL from the dynamically generated audio
            link.setAttribute('download', 'audio.mp3'); // Choose a filename for the downloaded file
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        next() {
            this.$emit('next')
        }
    },
    async mounted() {
        this.loading=true
        try {
            const response = await apiClient.post('/api/RootAnalysis/GetStory', {
                userId: 1,
                languageID: this.$store.state.language,
                words: this.learningWords.map(word => word.rootId).join(','),
            });
            this.story = response.data.story
            this.roots = response.data.roots
            this.loading = false
            this.createAudio()
        }
        catch (error) {
            this.loading = false
            console.error('Error updating word:', error);
        }
    }
};
</script>

<style scoped>
.buttons{
    display:flex;
    justify-content: center;
}
.wrapper{
    width:100%;
    display:flex;
    align-items: center;
    justify-content:center;
}
.content{
    width: 50%;
    padding:30px;
    height:75vh;
    overflow-y:scroll;
    border:1px solid black;
    border-radius:15px;
}

</style>