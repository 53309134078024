<template>
    <div class="manager-wrapper">
        <TranscriptInput
            v-if="stage == 'input'"
            @next="stage = 'analysis'"
            @setData="setWorkflowData"
            :workflowData="workflowData"
        ></TranscriptInput>
        <TranscriptResults
        v-if="stage == 'analysis'"
        @next="stage = 'select'"
        @setData="setWorkflowData"
        :workflowData="workflowData"
        ></TranscriptResults>
    </div>
</template>

<script>
import TranscriptInput from './TranscriptInput.vue'
import TranscriptResults from './TranscriptResults.vue'

export default {
    name: 'TranscriptManager',
    components: {
        TranscriptInput,
        TranscriptResults,
    },
    data() {
        return {
            stage: 'input',
            componentKey: 0,
            workflowData:{}
        }
    },
    methods: {
        setWorkflowData(data) {
            this.workflowData = { ...this.workflowData, ...data };
            //Forces a refresh
            this.componentKey++;
        },
    }
}
</script>

<style scoped>
.manager-wrapper{
    height:90%;
}
</style>
