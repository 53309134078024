<template>
  <div>
    <textarea @input='setInternalText' v-model="text" ref="textarea" rows="4" cols="50"></textarea>
    <div>
      <button v-for="(accent, index) in accents" :key="index" @click="addAccent(accent.key)">
        {{ accent.display }}
      </button>
      <button @click="startRecording" v-if="!isRecording"><font-awesome-icon icon="microphone"></font-awesome-icon></button>
      <button @click="stopRecording" v-if="isRecording"><font-awesome-icon icon="pause"></font-awesome-icon></button>
      <button @click="sendRecording" :disabled="!recordedBlob || loading"><font-awesome-icon icon="paper-plane"></font-awesome-icon></button>
      <button @click="playRecording" :disabled="!recordedBlob || loading"><font-awesome-icon icon="play"></font-awesome-icon></button>
    </div>
  </div>
</template>

<script>
import apiClient from '@/api';

export default {
  data() {
    return {
      text: "",
      isRecording: false,
      recordedBlob: null,
      mediaRecorder: null,
      loading:false
    };
  },
  computed: {
    accents() {
      if (this.$store.state.language == "vi") {//Nêu tôi muơn đi, ban c
        return [
          { 'display': "ô", 'key': '^' },
          { 'display': "ơ", 'key': '*' },
          { 'display': "ó", 'key': '/' },
          { 'display': "ò", 'key': '\\' },
          { 'display': "ọ", 'key': '.' },
          { 'display': "õ", 'key': '~' },
          { 'display': "ỏ", 'key': '?' },
          { 'display': "đ", 'key': '-' },
        ]
      }
      else if (this.$store.state.language == 'zh') {
        return [
          { 'display': "é", 'key': '/' },
          { 'display': "è", 'key': '\\' },
          { 'display': "ê", 'key': '^' },
          { 'display': "ě", 'key': 'v' },
          { 'display': "ā", 'key': '-' },
        ]
      }
      else if (this.$store.state.language == 'fr') {
        return [
          { 'display': "ó", 'key': '/' },
          { 'display': "ò", 'key': '\\' },
          { 'display': "ô", 'key': '^' },
          { 'display': "ç", 'key': '?' },
        ]
      }
      else if (this.$store.state.language == 'es') {
        return ["ñ"]
      }
      else {
        return []
      }
    }
  },
  methods: {

    getAccentLetter(letter, accent) {
      let accents = {
        'a': { '/': 'á', '\\': 'à', '.': 'ạ', '~': 'ã', '?': 'ả', '^': 'â', '*': 'ă' ,'v': 'ǎ', '-': 'ā'},
        'ă': { '/': 'ắ', '\\': 'ằ', '.': 'ặ', '~': 'ẵ', '?': 'ẳ'},
        'â': { '/': 'ấ', '\\': 'ầ', '.': 'ậ', '~': 'ẫ', '?': 'ẩ'},
        'á': { '^': 'ấ',  '*': 'ắ' },
        'ạ': { '^': 'ậ',  '*': 'ặ' },
        'à': { '^': 'ầ',  '*': 'ằ' },
        'ả': { '^': 'ẩ',  '*': 'ẳ' },
        'ã': { '^': 'ẫ',  '*': 'ẵ' },


        'e': { '/': 'é', '\\': 'è', '.': 'ẹ', '~': 'ẽ', '^': 'ê', '?': 'ẻ', 'v':'ě', '-': 'ē' },
        'ê': { '/': 'ế', '\\': 'ề', '.': 'ệ', '~': 'ễ', '?': 'ể'},
        'é': { '^': 'ế' },
        'è': { '^': 'ề' },
        'ẹ': { '^': 'ệ' },
        'ẻ': { '^': 'ể' },
        'ẽ': { '^': 'ễ' },


        'i': { '/': 'í', '\\': 'ì', '.': 'ị', '~': 'ĩ', '?': 'ỉ', '-': 'ī' },

        'o': { '/': 'ó', '\\': 'ò', '.': 'ọ', '~': 'õ', '?': 'ỏ', '^': 'ô', '*': 'ơ', 'v': 'ǒ', '-': 'ō' },
        'ô': { '/': 'ố', '\\': 'ồ', '.': 'ộ', '~': 'ỗ', '?': 'ổ' },
        'ơ': { '/': 'ớ', '\\': 'ờ', '.': 'ợ', '~': 'ỡ', '?': 'ở' },
        'ó': { '^': 'ố', '*': 'ớ' },
        'ò': { '^': 'ồ', '*': 'ờ' },
        'ọ': { '^': 'ộ', '*': 'ợ' },
        'õ': { '^': 'ỗ', '*': 'ỡ' },
        'ỏ': { '^': 'ổ', '*': 'ở' },

        'u': { '/': 'ú', '\\': 'ù', '.': 'ụ', '~': 'ũ', '?': 'ủ', '*': 'ư', '-': 'ū' },
        'ư': { '/': 'ứ', '\\': 'ừ', '.': 'ự', '~': 'ữ', '?': 'ử' },
        'ú': { '*': 'ứ' },
        'ù': { '*': 'ừ' },
        'ụ': { '*': 'ự' },
        'ũ': { '*': 'ữ' },
        'ủ': { '*': 'ử' },

        'y': { '/': 'ý', '\\': 'ỳ', '.': 'ỵ', '~': 'ỹ', '?': 'ỷ' },

        'n': { '~': 'ñ' },
        'd': { '-': 'đ' },
        'c': { '?': 'ç' },
      }
      try {
        return accents[letter][accent]
      }
      catch (e) {
        return false
      }
    },

    addAccent(accent) {
      const input = this.$refs.textarea;
      const cursorPos = input.selectionStart;
      const letter = this.text.substring(cursorPos - 1, cursorPos);
      let accentLetter = this.getAccentLetter(letter, accent)
      if (!accentLetter) return
      const newText =
        this.text.substring(0, cursorPos - 1) + accentLetter + this.text.substring(cursorPos);
      this.text = newText;
      // Set the cursor position after the inserted accented letter
      input.selectionStart = cursorPos;
      input.selectionEnd = cursorPos;
      input.focus();
      this.$emit('setText', this.text)
    },
    setInternalText() {
      this.$emit('setText', this.text)
    },
    clear(){
      this.text=""
    },
    async startRecording() {
        this.recordedBlob = null;
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.mediaRecorder = new MediaRecorder(stream);
        const chunks = [];
        this.mediaRecorder.ondataavailable = (e) => chunks.push(e.data);
        this.mediaRecorder.onstop = () => {
            this.recordedBlob = new Blob(chunks, { type: 'audio/wav' });
            this.mediaRecorder.stream.getTracks().forEach(track => track.stop());
        };
        this.mediaRecorder.start();
        this.isRecording = true;
    },
    async stopRecording() {
        this.mediaRecorder.stop();
        this.isRecording = false;
    },
    async sendRecording() {
        this.loading=true
        const formData = new FormData();
        formData.append('Audio', this.recordedBlob);
        formData.append('Language', this.$store.state.language);
        console.log(this.$store.state.language)
        const response = await apiClient.post('/api/RootAnalysis/TranscribeAudio', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if (response.status == 200) {
            const text = await response.data.text;
            this.text = text
            this.loading=false
            this.$emit('setText', this.text)
            return text;
        } else {
            this.loading=false
            throw new Error('Failed to send voice blob');
        }
    },
    playRecording() {
        const audioUrl = URL.createObjectURL(this.recordedBlob);
        const audio = new Audio(audioUrl);
        audio.play();
    },
  },
};
</script>

<style scoped>
textarea, button{
  font-size:16px;
}
textarea{
  width:100%;
}
</style>
