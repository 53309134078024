<template>
    <MainNavigation>
        <div class="wrapper">
                <PillSelector :options="[{ text: 'I want to chat', value: 'conversation' }, { text: 'I have a transcript', value: 'transcript' }]"
                    @select="(newState) => selectedView = newState"
                    class="pill">
                </PillSelector>
                <div class="content">
                    <TranscriptInput v-if="selectedView == 'transcript'"></TranscriptInput>
                    <ChatbotManager v-if="selectedView == 'conversation'"></ChatbotManager>
                </div>
        </div>
    </MainNavigation>
</template>

<script>

import MainNavigation from '@/components/General/MainNavigation.vue'
import TranscriptInput from '@/components/Home/Transcript/TranscriptManager.vue'
import ChatbotManager from '@/components/Home/Chatbot/ChatbotManager.vue'
import PillSelector from '@/components/General/PillSelector.vue'

export default {
    components: {
        MainNavigation,
        TranscriptInput,
        ChatbotManager,
        PillSelector
    },
    data() {
        return {
            selectedView: 'conversation',
        };
    },
    computed: {
    },
    methods: {
    },
};

</script>

<style scoped>
.wrapper {
    background-color: white;
    margin: 20px;
    height: 90%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.content{
    width:100%;
    height:100%;
}
.pill{
    width:50%;
    margin:20px;
}

@media (max-width: 767px){
    .pill{
        width:100%;
        font-size:12px;
        margin-top:0px;
    }
    .wrapper{
        margin:10px;
    }
}
</style>
