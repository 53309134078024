<template>
    <MainNavigation>
      <div class="wrapper">
          <div v-if="sentences.length!=0" class="contentWrapper">
                <div class="allInput">
                    <div>
                        <TranslatableButton @click="sentences=[]"
                            :disabled="loading"
                            text="Back" />
                        <p>My attempt...</p>
                        <TypableTextarea @setText='setInput' ref="textarea"></TypableTextarea>
                    </div>
                    <div>
                        <TranslatableButton @click="checkSentence()"
                            :disabled="loading"
                            text="Check" />
                            <p v-if="correction">{{correction.correction}}</p>
                    </div>
                </div>
                <div class="wordsWrapper" v-if="language">
                  {{sentences[currentSentence]}}
                      <TranslatableButton @click="playAudio()"
                          :disabled="loading"
                          text="Play" />
                      <TranslatableButton @click="increaseCurrent()"
                          :disabled="loading"
                          text="Next" />
                </div>
            </div>
          <div v-if="sentences.length==0" class="initialWrapper">
            <textarea v-model='text' class="textarea" @input="hasChanged=true"></textarea>
                <TranslatableButton @click="splitSentence()"
                    :disabled="loading"
                    text="Go" />
          </div>
      </div>
    </MainNavigation>
</template>

  <script>
  import apiClient from '@/api';
  import { mapGetters } from 'vuex';
  import TranslatableButton from '@/components/General/TranslatableButton.vue'
  import TypableTextarea from '@/components/General/TypableTextarea.vue'
  import MainNavigation from '@/components/General/MainNavigation.vue'

  export default {
      components: {
        TranslatableButton,
        TypableTextarea,
        MainNavigation
      },
      data() {
        return{
            loading:false,
            userInput:'',
            currentAudio:null,
            hasChanged:false,
            text:'',
            sentences:[],
            currentSentence:0
        }
      },
      computed: {
        ...mapGetters(['languageName', 'language']),
      },
      methods: {
        splitSentence(){
          this.sentences = this.text.match(/[^.!?。？]+[.!?。？]+(?:\s|$)/g) || [];
        },
        increaseCurrent(){
          if(this.currentSentence<this.sentences.length-1){
            this.currentSentence++
            this.hasChanged=true
          }
        },
        setInput(input) {
            this.userInput = input
        },
        async playAudio(){
            if(!this.hasChanged && this.currentAudio){
                this.currentAudio.play()
                return
            }
            else{
              this.loading=true
                const response = await fetch(process.env.VUE_APP_API_URL + '/api/RootAnalysis/SynthesizeTextWithTranslation', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        text: this.sentences[this.currentSentence].split('.').join(""),
                        languageId: this.$store.state.language
                    }),
                });
                const blob = await response.blob();
                const audioUrl = URL.createObjectURL(blob);
                const audio = new Audio(audioUrl);
                this.currentAudio = audio
                this.currentAudio.play()
                this.hasChanged=false
                this.loading=false
            }
        },
        async checkSentence() {
            try {
                const response = await apiClient.post('/api/RootAnalysis/GetCorrection', {
                    languageId: this.$store.state.language,
                    text: this.userInput,
                });
                if (response.status === 200) {
                    this.correction=response.data
                    this.pageState=1
                }
            } catch (error) {
                this.error = error
                console.error('Error updating word:', error);
            }
        },
        async sayWord(word){
            word.playAudio()
        }
      },
  }
  </script>

  <style scoped>

  .wrapper {
      background-color: white;
      margin: 20px;
      height: 90%;
      border-radius: 15px;
      display:flex;
      flex-direction: column;
  }
  .initialWrapper {
      display: flex;
      flex-direction:column;
      align-items: center;
      justify-content: space-around;
      height:100%;
      width:100%;
  }
  .initialWrapper textarea{
    width:80%;
    height:50%;
  }
  .contentWrapper {
      display: flex;
      align-items: center;
      height:100%;
      width:100%;
  }
  .contentWrapper>div{
    flex:1;
  }

  .allInput{
    height:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: space-around;
    border-right:1px solid black;
  }
  .allInput>div{
    width:80%;
  }

  .wordsWrapper{
    display:flex;
    flex-direction:column;
    padding:40px;
  }
  .correction{
    width:50%;
    font-size:20px;
  }
  .textarea{
    width:100%;
  }
  .used{
    background-color:gray;
  }
  @media (max-width: 767px){
      .wrapper>div {
          font-size:24px;
      }
  }
  </style>
