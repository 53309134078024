<template>
    <div>
        <div v-if="!initializing" class="wrapper">
            <div class="contentWrapper">
                <div class="allInput">
                    <div>
                        <p>Help me say...</p>
                        <textarea v-model='helpSay' class="textarea" @input="hasChanged=true"></textarea>
                        <TranslatableButton @click="playHelpAudio()" 
                            :disabled="loading" 
                            text="Send" />
                    </div>
                    <div>
                        <p>My attempt...</p>
                        <TypableTextarea @setText='setInput' ref="textarea"></TypableTextarea>
                    </div>
                    <div>
                        <TranslatableButton @click="checkSentence()" 
                            :disabled="loading" 
                            text="Check" />
                            <p v-if="correction">{{correction.correction}}</p>
                    </div>
                </div>
                <div class="wordsWrapper">
                    <div v-for="word in words" :key="word.rootId" @click="word.finished=!word.finished"
                        :class="`word ${(word.finished)?'used':''}`">
                        <span>{{ word.translation }}</span>
                        <font-awesome-icon icon="play" @click="sayWord(word)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
  <script>
  import apiClient from '@/api';
  import { mapGetters } from 'vuex';
  import WordObject from '@/components/Lesson/WordObject.js'
  import TranslatableButton from '@/components/General/TranslatableButton.vue'
  import TypableTextarea from '@/components/General/TypableTextarea.vue'
  
  export default {
    props: {
        lesson: Object,
    },
      components: {
        TranslatableButton,
        TypableTextarea
      },
      data() {
        return{
            words:this.lesson.lessonRoots.map(word => new WordObject(word)),
            loading:false,
            userInput:'',
            correction:null,
            helpSay:'',
            currentAudio:null,
            hasChanged:false
        }
      },
      computed: {
        ...mapGetters(['languageName', 'language']),
        progress(){
            return 100-(100*this.words.length/this.oglength)
        }
      },
      methods: {
        setInput(input) {
            this.userInput = input
        },
        async playHelpAudio(){
            if(!this.hasChanged && this.currentAudio){
                this.currentAudio.play()
            }
            else{
                const response = await fetch(process.env.VUE_APP_API_URL + '/api/RootAnalysis/SynthesizeTextWithTranslation', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        text: this.helpSay,
                        languageId: this.lesson.languageId
                    }),
                });
                const blob = await response.blob();
                const audioUrl = URL.createObjectURL(blob);
                const audio = new Audio(audioUrl);
                this.currentAudio = audio 
                this.currentAudio.play()
                this.hasChanged=false
            }
        },
        async checkSentence() {
            try {
                const response = await apiClient.post('/api/RootAnalysis/GetCorrection', {
                    languageId: this.$store.state.language,
                    text: this.userInput,
                });
                if (response.status === 200) {
                    this.correction=response.data
                    this.pageState=1
                }
            } catch (error) {
                this.error = error
                console.error('Error updating word:', error);
            }
        },
        async sayWord(word){
            word.playAudio()
        }
      },
  }
  </script>
  
  <style scoped>
  .wrapper{
    height:100%;
  }
  .contentWrapper {
      display: flex;
      align-items: center;
      height:100%;
      width:100%;
  }
  .contentWrapper>div{
    flex:1;
  }

  .allInput{
    height:100%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: space-around;
    border-right:1px solid black;
  }
  .allInput>div{
    width:80%;
  }

  .wordsWrapper{
    display:flex;
    flex-wrap:wrap;
  }
  .wordsWrapper>div{
    border:1px solid black;
    padding:10px;
    display:flex;
    flex-direction:column;
  }
  .correction{
    width:50%;
    font-size:20px;
  }
  .textarea{
    width:100%;
  }
  .used{
    background-color:gray;
  }
  @media (max-width: 767px){
      .wrapper>div {
          font-size:24px;
      }
  }
  </style>
  