<template>
    <div id="input-analysis-wrapper">
      <textarea v-model="inputText" placeholder="Enter text"> </textarea>
      <TranslatableButton
        :disabled="!inputText" :loading="loading" text="Go"
        @click="sendData"
        class="gobutton">
      </TranslatableButton>
      <div class='error' v-if="error">{{ error }}</div>
    </div>
  </template>

  <script>
  import apiClient from '@/api';
  import { mapMutations, mapGetters } from 'vuex';
  import TranslatableButton from '@/components/General/TranslatableButton.vue'

  export default {
    props: {
      workflowData: Object
    },
    components: {
      TranslatableButton
    },
    data() {
      return {
        inputText: '',
        loading: false,
        error:''
      };
    },
    computed:{
      ...mapGetters(['language']),
    },
    methods: {
      ...mapMutations(['setLanguage']),
      async sendData() {
        this.loading = true
        try {
          const response = await apiClient.post('/api/RootAnalysis', {
            uID: 1,
            text: this.inputText,
            languageID: this.language,
          });
          this.$emit('setData', {
            'rawResults': response.data
          })
          console.log(response.data)
          this.loading = false
          this.$emit('next')
        } catch (error) {
          this.loading = false
          this.error=error
        }
      },
    },
  };
  </script>

  <style scoped>
  #input-analysis-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  textarea {
    width: 80%;
    height:500px;
    margin-bottom:20px;
  }
  .gobutton{
    width:100px;
  }
  </style>
