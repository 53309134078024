<template>
    <div class="wrapper">
        <div>{{ word.translation }}</div>
        <TypableTextarea @setText='setText' ref='textarea'></TypableTextarea>
        <TranslatableButton @click="checking=true" text="Check">
        </TranslatableButton>
        <div v-if="checking == true">
            <span v-for="(char, index) in word.rootId.split('')" :key="index"
                :class="{ 'incorrect': char !== userInput[index] }">{{ char }}</span>
            <div>{{word.pronunciation}}</div>
        </div>
    </div>
</template>

<script>

import TranslatableButton from '@/components/General/TranslatableButton.vue'
import TypableTextarea from '@/components/General/TypableTextarea.vue'
export default {
    props: {
        word: Object,
        moveTo: Function,
    },
    components: {
        TranslatableButton,
        TypableTextarea
    },
    data() {
        return {
            userInput: '',
            checking: false
        }
    },
    methods: {
        moveToPosition(position) {
            this.moveTo(position);
        },
        setText(text) {
            this.userInput = text
        }
    },
    mounted() {
    },
    watch: {
        word(newWord, oldWord) {
            if (newWord !== oldWord) {
                this.userInput=''
                this.checking=false
                this.$refs.textarea.clear()
            }
        }
    },
};
</script>

<style scoped>
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.wrapper>div {
    margin-bottom: 15px;
    font-size:48px;
}

.hidden-translation {
    background-color: black;
}

.hidden-translation:hover {
    background-color: white;
}

.incorrect {
    color: red;
}
@media (max-width: 767px){
    .wrapper>div {
        font-size:24px;
    }
}
</style>
