<template>
  <div class="text-breakdown">
    <span v-for="(word, index) in text" :key="index" class='word' :class="{
      'known': word.dontshow || word.level === 1 || !word.savable,
      'learning': word.level === 0 && word.savable && !word.dontshow,
      'new': word.level === -1 && word.savable && !word.dontshow,
      'selected': selectedWord === word.root,
    }" @click="selectWord(word, $event)">

      
      <span v-if="word.level === 1 && showLearning">{{ word.root }}</span>
      <span v-if="word.level === 1 && !showLearning">{{word.translation}}</span>
      <span v-if="word.level === 0 && !showLearning">{{ word.translation }}</span>
      <span v-if="word.level === 0 && showLearning">{{word.root}}</span>
      <span v-if="word.level === -1" class='withPron'>
        <span>{{ word.text }} ({{word.translation}})</span>
        <span>{{word.pronunciation}}</span>
      </span>


      <div v-if="selectedWord === word.root" class="info-box" :class="{
        'known': word.dontshow || word.level === 1 || !word.savable,
        'learning': word.level === 0 && word.savable && !word.dontshow,
        'new': word.level === -1 && word.savable && !word.dontshow,
      }" @click.stop="selectedWord = null" :style="{ top: position.top + 'px', left: position.left + 'px' }">
        <div v-if="loading">Loading...</div>
        
          <span v-if="word.level != 0">{{ word.root }}</span>
          <span v-if="word.level != 0">{{ word.translation }}</span>
          <span v-if="word.level != 0">{{ word.pronunciation }}</span>
          <span v-if="word.level != 0">{{ word.sentence1 }}</span>
          <span v-if="word.level != 0">{{ word.translation1 }}</span>
          
          <span v-if="word.level === 0">{{ word.mnemonics }}</span>
          <span v-if="word.level === 0" class="hiddenHover">{{ word.root }}</span>
          <span v-if="word.level === 0" class="hiddenHover">{{ word.translation }}</span>
          <span v-if="word.level === 0" class="hiddenHover">{{ word.pronunciation }}</span>
          
        <div>
          <button class="learning" v-if="word.level == 1 || word.level == -1" @click.stop="updateWord(word, false)">Learning</button>
          <button class="known" v-if="word.level == -1 || word.level == 0" @click.stop="updateWord(word, true)">Known</button>
        </div>
      </div>
      <br v-if="breaks.indexOf(word.text) > -1" />
    </span>
  </div>
</template>

<script>
import apiClient from '@/api';
export default {
  props: {
    text: {
      type: Array,
      required: true,
    },
    showLearning: {
      type: Boolean,
      required: false,
      default: true,  // Set the default value here
    },
  },
  data() {
    return {
      selectedWord: null,
      position: { top: 0, left: 0 },
      loading: false
    }
  },
  computed: {
    breaks() {
      return ['\n', '\n\n', '。']
    }
  },
  methods: {
    async selectWord(word, event) {
      console.log("")
      this.loading = true
      this.position = {
        top: event.target.offsetTop - 120, // Adjust the positioning as necessary
        left: event.target.offsetLeft
      };
      this.selectedWord = word.root;
        this.loading = true
        try {
          const response = await apiClient.get(`/api/RootAnalysis/GetWordDetail?root=${word.root}&languageId=${this.$store.state.language}`);
          console.log("FROM SELECT")
          console.log(response.data)
          this.$emit('updateWord', word.root, response.data)
          this.loading = false
        } catch (error) {
          this.loading = false
          console.error("Error fetching word detail:", error);
        }
    },

    async updateWord(word, known) {
      if (this.loading) return
      this.loading = true
      try {
        const response = await apiClient.post('/api/RootAnalysis/AddOrUpdateWord', {
          userId: 1,
          rootId: this.selectedWord,
          languageId: this.$store.state.language,
          known: known,
        });
        if (response.status === 200) {
          this.loading=false
          this.$emit('updateWord', this.selectedWord, {...word, level:(known) ? 1 : 0})
          this.selectedWord=null
        }
        this.loading = false
      } catch (error) {
        this.loading = false
        console.error('Error updating word:', error);
      }
    },
  }
};
</script>

<style scoped>
.known {
  background-color: default;
}
.known>div{
  background-color:white;
}

.learning {
  background-color: var(--my-background);
}

.new {
  background-color: var(--new-background);
}

.selected, .word:hover {
  background-color: #fff766;
}

.text-breakdown span {
  margin-right: 5px;
}

.info-box {
  position: absolute;
  border: 1px solid black;
  padding: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  display: flex;
  flex-direction: column;
}
.hiddenHover{
  background-color:black;
}
.hiddenHover:hover{
  background-color:rgba(0,0,0,0);
}
input {
  display: block;
}

@media (max-width: 767px) {
  span {
    font-size: 12px;
  }
}
</style>
