export default class WordObject {
    constructor(wordObj) {
        // Assign existing properties
        this.rootId = wordObj.rootId;
        this.translation = wordObj.translation;
        this.sentence1 = wordObj.sentence1;
        this.sentence2 = wordObj.sentence2;
        this.sentence3 = wordObj.sentence3;
        this.translation1 = wordObj.translation1;
        this.translation2 = wordObj.translation2;
        this.translation3 = wordObj.translation3;
        this.pronunciation = wordObj.pronunciation;
        this.language = wordObj.languageId

        // Add new properties
        this.translationAudio = null;
        this.sentence1Audio = null;
        this.sentence2Audio = null;
        this.sentence3Audio = null;
        this.level = 0;
        this.currentSentence = 0;

        this.currentSentenceText=''
        this.currentTranslationText=''
    }

    async getAudio(text) {
        const response = await fetch(process.env.VUE_APP_API_URL + '/api/RootAnalysis/SynthesizeText', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                text: text,
                languageId: this.language
            }),
        });
        const blob = await response.blob();
        const audioUrl = URL.createObjectURL(blob);
        const audio = new Audio(audioUrl);
        return audio
    }
    //Tôi học về lĩnh vực trí tuệ nhân tạo và lập trình máy tính . Bạn học ngành gì ?
    async playAudio() {
        if (!this.translationAudio) {
            this.translationAudio = await this.getAudio(this.rootId)
        }
        this.translationAudio.play()
    }

    async newSentence() {
        this.currentSentence++
        switch (this.currentSentence) {
            case 1:
                this.currentSentenceText=this.sentence1
                this.currentTranslationText=this.translation1
                break;
            case 2:
                this.currentSentenceText=this.sentence2
                this.currentTranslationText=this.translation2
                break;
            case 3:
                this.currentSentenceText=this.sentence3
                this.currentTranslationText=this.translation3
                break;
            default:
                this.currentSentence=0
                this.newSentence
        }
        this.playCurrentSentence()
    }

    async playCurrentSentence() {
        switch (this.currentSentence) {
            case 1:
                if (!this.sentence1Audio) this.sentence1Audio = await this.getAudio(this.sentence1)
                this.sentence1Audio.play()
                break
            case 2:
                if (!this.sentence2Audio) this.sentence2Audio = await this.getAudio(this.sentence2)
                this.sentence2Audio.play()
                break
            case 3:
                if (!this.sentence3Audio) this.sentence3Audio = await this.getAudio(this.sentence3)
                this.sentence3Audio.play()
                break
            default:
                this.currentSentence = 0
                this.newSentence()
                break
        }
    }
}