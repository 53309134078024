<template>
    <div class="manager-wrapper">

        <ChatbotHome v-if="state == 'home'" @create="state = 'create'" @use="goToUse"></ChatbotHome>

        <UseChatbot v-if="state == 'use'" :chatbotId="id"></UseChatbot>

        <CreateChatbot v-if="state == 'create'" @use="state = 'home'"></CreateChatbot>
    </div>
</template>

<script>
import ChatbotHome from './ChatbotHome.vue'
import UseChatbot from './UseChatbot.vue'
import CreateChatbot from './CreateChatbot.vue'

export default {
    name: 'ChatbotManager',
    components: {
        ChatbotHome,
        UseChatbot,
        CreateChatbot
    },
    data() {
        return {
            state: 'home',
            componentKey: 0,
            id: 0
        }
    },
    methods: {
        goToUse(id) {
            this.id=id
            this.state='use'
        }
    }
}
</script>

<style scoped>
.manager-wrapper {
    height: 90%;
}
</style>