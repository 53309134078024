<template>
    <div class="wrapper">
        <input v-model="chatbot.name" placeholder="Name" />
        <p>f"I am learning [Language]. Please role play with me and only respond in [Language].</p>
        <p>For this conversation, you will be responding as if you were the following person and I will continue the role play with you.</p>
        <p>Following your role's description, please respond in character and wait for me to continue the conversation.</p>
        <textarea v-model="chatbot.chatbotText" placeholder="Chatbot Text"></textarea>
        <textarea v-model="chatbot.displayText" placeholder="Display Text"></textarea>
        <input type="file" @change="handleFileUpload">
        <button @click="submitChatbot">Submit</button>
        <div v-if="error">{{ error }}</div>
    </div>
</template>

<script>
import apiClient from '@/api';
export default {
    data() {
        return {
            chatbot: {
                name: '',
                chatbotText: '',
                displayText: '',
                imageUrl: ''
            },
            imageFile: null,
      error: ''  // Error message string
        };
    },
    methods: {
        handleFileUpload(event) {
            this.imageFile = event.target.files[0];
        },
        async submitChatbot() {
            let formData = new FormData();
            formData.append('name', this.chatbot.name);
            formData.append('chatbotText', this.chatbot.chatbotText);
            formData.append('displayText', this.chatbot.displayText);
            formData.append('languageId', this.$store.state.language);
            formData.append('image', this.imageFile);

            apiClient.post('/api/Chatbot/NewChatbot', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    // Emit an event with the new ID
                    //this.$emit('use', response.data.id);
                    console.log(response)
                    this.$emit('use');
                })
                .catch(error => {
                    // Set the error message
                    this.error = error.response.data.message || 'An error occurred while submitting the chatbot.';
                });
        }
    }
}
</script>

<style scoped>
.wrapper {
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.wrapper>* {
    margin-bottom: 20px;
}

textarea {
    width: 500px;
    height: 100px;
}
</style>