<template>
  <div class="roots-wrapper">
    <h2>New ({{ newRoots.length }}) Learning({{learningRoots.length}})</h2>
    <div v-for="root in newRoots" :key="root" class="newWords">
      <NewWord :root="root" @updateWord="updateWord" @ignoreWord="ignoreWord" />
    </div>
    <h2>Learning</h2>
    <ol>
    <div v-for="root in learningRoots" :key="root" class="newWords">
      <LearningWord :root="root" @updateWord="updateWord"/>
    </div>
    </ol>
  </div>

</template>

<script>
import NewWord from '@/components/General/RootsList/NewWord.vue'
import LearningWord from '@/components/General/RootsList/LearningWord.vue'

export default {
  components: {
    NewWord,
    LearningWord
  },
  props: {
    text: {
      type: Array,
      required: true,
    },
  },
  computed: {
    newRoots() {
      return this.getUniqueRoots(-1);
    },
    learningRoots() {
      return this.getUniqueRoots(0);
    },
  },
  methods: {
    getUniqueRoots(level) {
      const roots = this.text
        .filter((word) => word.savable && word.level === level && !word.dontshow);

      const rootCounts = new Map();

      roots.forEach((word) => {
        const root = word.root;
        if (rootCounts.has(root)) {
          const entry = rootCounts.get(root);
          entry.count += 1;
        } else {
          rootCounts.set(root, { root: word, count: 1 });
        }
      });

      return Array.from(rootCounts.values())
        .sort((a, b) => b.count - a.count);
    },
    updateWord(rootId, level) {
      this.$emit('updateWord', rootId, level )
    },
    ignoreWord(rootId) {
      this.$emit('ignoreWord', rootId )
    },
  },
};
</script>

<style scoped>
.roots-wrapper{
  height:100%;
  overflow-y:scroll;
  display:flex;
  flex-direction:column;
}
@media (max-width: 767px){
  .roots-wrapper{
    overflow-y:auto;
  }
}
</style>
