export default {
    'New Chatbot': {
        'en': 'New Chatbot',
        'es': 'nuevo chatbot',
        'fr': 'nouveau chatbot',
        'pt': 'Novo Chatbot',
        'ja': '新しいチャットボット',
        'vi': 'Trò Chuyện Mới',
        'zh': '新聊天机器人',
    },
    'Loading': {
        'en': 'Loading',
        'es': 'Cargando',
        'fr': 'Chargement',
        'pt': 'Carregando',
        'ja': 'ローディング',
        'vi': 'đang tải',
        'zh': '加载中',
    },
    'Known': {
        'en': 'Known',
        'es': 'Conocido',
        'fr': 'Connu',
        'pt': 'Conhecido',
        'ja': '既知',
        'vi': 'Đã biết',
        'zh':'已知',
    },
    'Unknown':{ 'en': 'Unknown', 'es': 'Desconocido', 'fr': 'Inconnu', 'pt': 'Desconhecido', 'ja': '不明', 'vi': 'chưa biết',
        'zh': '未知', },
    'Detail':{ 'en': 'Detail', 'es': 'Detalle', 'fr': 'Détail', 'pt': 'Detalhe', 'ja': '詳細', 'vi': 'chi tiết',
        'zh': '细节', },
    'Ignore':{ 'en': 'Ignore', 'es': 'ignorar', 'fr': 'ignorer', 'pt': 'Ignorar', 'ja': '無視する', 'vi': 'Phớt lờ',
        'zh': '忽略', },
    'Send':{
        'en': 'Send',
        'es': 'Enviar',
        'fr': 'Envoyer',
        'pt': 'Enviar',
        'ja': '送信',
        'vi': 'Gửi',
        'zh': '发送',
    },
    'Selection':{
        'en': 'Selection',
        'es': 'Selección',
        'fr': 'Sélection',
        'pt': 'Seleção',
        'ja': '選択',
        'vi': 'Lựa chọn',
        'zh': '选择',
    },
    'Go':{ 'en': 'Go', 'es': 'Ir', 'fr': 'Aller', 'pt': 'Ir', 'ja': '行く', 'vi': 'đi',
        'zh': '去', },
    'Next':{ 'en': 'Next', 'es': 'Próxima', 'fr': 'Suivante', 'pt': 'Próxima', 'ja': '次', 'vi': 'Kế tiếp',
        'zh': '下一个', },
    'Read':{ 'en': 'Read', 'es': 'Leer', 'fr': 'Suivante', 'pt': 'Leia', 'ja': '読む', 'vi': 'Đọc',
        'zh': '读', },
    'Download':{ 'en': 'Download', 'es': 'Descargar', 'fr': 'Télécharger', 'pt': 'Baixe', 'ja': 'ダウンロード', 'vi': 'tải xuống',
        'zh': '下载', },
    'Study':{ 'en': 'Study', 'es': 'Estudiar', 'fr': 'Étudier', 'pt': 'Estudo', 'ja': '検討', 'vi': 'Nghiên cứu',
        'zh': '学习', },
    'Unselect':{ 'en': 'Unselect', 'es': 'Deseleccionar', 'fr': 'Désélectionner', 'pt': 'Desmarcar', 'ja': '選択を解除', 'vi': 'Bỏ chọn',
        'zh': '取消选择', },
    'Swap':{ 'en': 'Swap', 'es': 'Cambiar', 'fr': 'Changer', 'pt': 'Cambiar', 'ja': '', 'vi': '',
        'zh': '交换', },
    'Bad':{
        'en': 'Bad',
        'es': 'Malo',
        'fr': 'Mauvais',
        'pt': 'Ruim',
        'ja': '悪い',
        'vi': 'Xấu',
        'zh': '坏的',
    },
    'Okay':{
        'en': 'Okay',
        'es': 'Bueno',
        'fr': "D'accord",
        'pt': 'OK',
        'ja': 'わかった',
        'vi': 'Đồng ý',
        'zh': '好的',
    },
    'Perfect':{
        'en': 'Perfect',
        'es': 'Perfecto',
        'fr': 'Parfait',
        'pt': 'Perfeito',
        'ja': '完璧',
        'vi': 'Hoàn hảo',
        'zh': '完美的',
    },
    'Start Recording':{
        'en': 'Start Recording',
        'es': 'Empezar a grabar',
        'fr': "Commencer l'enregistrement",
        'pt': 'Comece a gravar',
        'ja': '録音を開始',
        'vi': 'Bắt đầu ghi âm',
        'zh': '开始录音',
    },
    'Stop Recording':{
        'en': 'Stop Recording',
        'es': 'Para de grabar',
        'fr': "Arrête d'enregistrer",
        'pt': 'Pare de gravar',
        'ja': '録音を停止',
        'vi': 'Dừng ghi',
        'zh': '停止录音',
    },
    'Play Audio':{
        'en': 'Play Audio',
        'es': 'Reproducir audio',
        'fr': "Lire l'audio",
        'pt': 'Reproduzir áudio',
        'ja': 'オーディオを再生する',
        'vi': 'Phát âm thanh',
        'zh': '播放音频',
    },
    "I don't know":{
        'en': "I don't know",
        'es': 'No sé',
        'fr': 'Je ne sais pas',
        'pt': 'Não sei',
        'ja': 'わからない',
        'vi': 'Tôi không biết',
        'zh': '我不知道',
    },
    "Check":{
        'en': 'Check',
        'es': 'Arreglar',
        'fr': 'Réparer',
        'pt': 'Consertar',
        'ja': '修理',
        'vi': 'Sửa chữa',
        'zh': '查看',
    },
    "Cheat":{
        'en': 'Cheat',
        'es': 'Engañar',
        'fr': 'Tricher',
        'pt': 'Trair',
        'ja': '浮気',
        'vi': 'Gian lận',
        'zh': '欺骗',
    },
    "Submit":{
        'en': 'Submit',
        'es': 'Entregar',
        'fr': 'Soumettre',
        'pt': 'Enviar',
        'ja': '提出',
        'vi': 'Nộp',
        'zh': '提交',
    },
    'Create Lesson':{
        'en': 'Create Lesson',
        'es': 'Crear lección',
        'fr': 'Créer une leçon',
        'pt': 'Criar lição',
        'ja': 'レッスンの作成',
        'vi': 'Tạo bài học',
        'zh': '创建课程',
    },
    'See Text':{
        'en': 'See Text',
        'es': 'Ver Texto',
        'fr': 'Voir le texte',
        'pt': 'Ver texto',
        'ja': '本文を参照',
        'vi': 'Xem văn bản',
        'zh': '查看正文',
    },
    'Listen':{
        'en': 'Listen',
        'es': 'Escuchar',
        'fr': 'Écouter',
        'pt': 'Ouvir',
        'ja': '聞く',
        'vi': 'Nghe',
        'zh': '听',
    },
    'Welcome':{
        'en': 'Welcome',
        'es': 'Bienvenido',
        'fr': 'Bienvenu',
        'pt': 'Bem-vindo',
        'ja': 'いらっしゃいませ',
        'vi': 'Chào mừng',
        'zh': '欢迎',
    },
    'Go to lesson':{
        'en': 'Go to lesson',
        'es': 'ir a la lección',
        'fr': 'Aller à la leçon',
        'pt': 'Vá para a aula',
        'ja': 'レッスンに行く',
        'vi': 'Đi học',
        'zh': '去上课',
    },
    'Right':{
        'en': 'Right',
        'es': 'correcto',
        'fr': 'Correct',
        'pt': 'correto',
        'ja': '正しい',
        'vi': 'Chính xác',
        'zh': '正确的',
    },
    'Wrong':{
        'en': 'Wrong',
        'es': 'Equivocado',
        'fr': 'Faux',
        'pt': 'Errado',
        'ja': '間違っている',
        'vi': 'Sai',
        'zh': '错误的',
    },
    'Submit Translation':{
        'en': 'Submit Translation',
        'es': 'Enviar traducción',
        'fr': 'Soumettre la traduction',
        'pt': 'Enviar tradução',
        'ja': '翻訳を送信する',
        'vi': 'Gửi bản dịch',
        'zh': '提交翻译',
    },
    'Submit Mnemonic':{
        'en': 'Submit Mnemonic',
        'es': 'Enviar mnemotécnico',
        'fr': 'Soumettre un mnémonique',
        'pt': 'Enviar mnemônico',
        'ja': 'ニーモニックを送信する',
        'vi': 'Gửi bản ghi nhớ',
        'zh': '提交助记词',
    },
    'Settings':{
        'en': 'Settings',
        'es': 'Ajustes',
        'fr': 'Paramètres',
        'pt': 'Configurações',
        'ja': '設定',
        'vi': 'Cài đặt',
        'zh': '设置',
    },
    'Mnemonic Help':{
        'en': 'Mnemonic Help',
        'es': 'Ayuda mnemotécnica',
        'fr': 'Aide mnémonique',
        'pt': 'Ajuda mnemônica',
        'ja': 'ニーモニックヘルプ',
        'vi': 'Trợ giúp ghi nhớ',
        'zh': '助记符帮助',
    },
    'Back':{
        'en': 'Back',
        'es': 'Atrás',
        'fr': 'revenir',
        'pt': 'volte',
        'ja': '返る',
        'vi': 'quay lại',
        'zh': '回去',
    },
    'Play':{
        'en': 'Play',
        'es': 'Reproducir',
        'fr': 'Lire',
        'pt': 'reproduzir',
        'ja': '再生',
        'vi': 'phát âm thanh',
        'zh': '播放',
    }
}
