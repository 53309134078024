<template>
    <MainNavigation>
      <div class="wrapper" v-if="initialized">
        <div class="completedDisplay">
          <div :class="`${(lesson.usedInFlash)?'finished':''} ${(states[state]=='mnemonic')?'active':''}`" @click="state=0">FL</div>
          <div :class="`${(lesson.usedInWrite)?'finished':''} ${(states[state]=='write')?'active':''}`" @click="state=1" >WR</div>
          <div :class="`${(lesson.usedInSound)?'finished':''} ${(states[state]=='sound')?'active':''}`" @click="state=2">SO</div>
          <div :class="`${(lesson.usedInSay)?'finished':''} ${(states[state]=='speak')?'active':''}`" @click="state=3" >SA</div>
        </div>
        <MnemonicFlash v-if="states[state]=='mnemonic' && !loading" :lesson="lesson" class="content" @changeWord="changeWord" @finishCard="incraseState" />
        <WriteFlash v-if="states[state]=='write' && !loading" :lesson="lesson" class="content" @finishCard="incraseState" />
        <SoundFlash v-if="states[state]=='sound' && !loading" :lesson="lesson" class="content" @finishCard="incraseState" />
        <SayFlash2 v-if="states[state]=='speak' && !loading" :lesson="lesson" class="content" />
    </div>
    </MainNavigation>
  </template>
  
  <script>
  import MainNavigation from '@/components/General/MainNavigation.vue'
  import apiClient from '@/api';
  import MnemonicFlash from '@/components/Lesson/MnemonicFlash.vue'
  import SoundFlash from '@/components/Lesson/SoundFlash.vue'
  import SayFlash2 from '@/components/Lesson/SayFlash2.vue'
  import WriteFlash from '@/components/Lesson/WriteFlash.vue'
//   import TranslatableButton from '@/components/General/TranslatableButton.vue'
  import { mapGetters } from 'vuex';
  
  export default {
      components: {
        //   TranslatableButton: TranslatableButton,
          MainNavigation,
          MnemonicFlash,
          SoundFlash,
          SayFlash2,
          WriteFlash
      },
      data() {
          return {
            lesson:null,
            initialized:false,
            loading:true,
            state:0,
            states:['mnemonic', 'write', 'sound', 'speak']
          };
      },
      computed: {
        ...mapGetters(['languageName', 'language']),
        id() {
        return this.$route.params.id; // Access the route parameter
        }
      },
      methods: {
        incraseState(){
          console.log("Increase")
          this.state++
        },
        fetchLesson() {
            this.loading = true;
            if (!this.$route.params.id) {
                this.error = "Please go to your lesson list and get a lesson"
                this.loading = false;
                return
            }
            else{
                this.error=""
            }
            apiClient.get('/api/RootAnalysis/Lesson?LessonId=' + this.$route.params.id)
                .then(response => {
                    console.log(response.data[0])
                    this.lesson = response.data[0];
                    this.loading = false;
                    this.initialized=true
                })
                .catch(error => {
                    this.error = 'Failed to load lessons: ' + (error.response?.data.message || error.message);
                    this.loading = false;
                });
        },
        changeWord(wordId, property, value){
          console.log(this.lesson)
          this.lesson.lessonRoots = this.lesson.lessonRoots.map(root=>{
            if(root.rootId==wordId){
              if(property == "translation") root.root.translation = value
              if(property == "mnemonic") root.root.mnemonics = value
              return root
            }
            return root;
          })
          console.log(this.lesson)
        }
      },
        mounted() {
            this.fetchLesson();
        },
  }
  </script>
  
  <style scoped>
  .wrapper {
      background-color: white;
      margin: 20px;
      height: 90%;
      border-radius: 15px;
      display:flex;
      flex-direction: column;
  }
  .completedDisplay{
    flex:1;
    display:flex;
    justify-content:start;
    margin-top:15px;
  }
  .completedDisplay>div{
    padding:5px;
    margin:10px;
    border:1px solid black;
    background-color:grey;
    display:flex;
    justify-content: center;
    align-items: center;
    width:20px;
    height:20px;
    border-radius:3px;
  }
  .completedDisplay .finished{
    background-color:white;
  }
  div.completedDisplay .active{
    background-color:var(--terciaryBack);
  }
  .content{
    flex-grow:3;
  }
  @media (max-width: 767px) {
  }
  </style>
  