<template>
  <MainNavigation>
    <div class="wrapper">

        <div v-if="toSource" @click="toSource=!toSource">{{languageName}} to English</div>
        <div v-if="!toSource" @click="toSource=!toSource">English to {{languageName}}</div>
        <div class="inputControl">
          <TypableTextarea @setText='setInput' ref="textarea"></TypableTextarea>
          <TranslatableButton text="Go" @click='sendSentence()' :loading="loading"  />
        </div>
        <BreakdownText :text="response" @updateWord="updateWord" :showLearning="toSource" />
        <textarea class="textarea"></textarea>
        {{error}}
  </div>
  </MainNavigation>
</template>

<script>
import MainNavigation from '@/components/General/MainNavigation.vue'
import apiClient from '@/api';
import TranslatableButton from '@/components/General/TranslatableButton.vue'
import TypableTextarea from '@/components/General/TypableTextarea.vue'
import BreakdownText from '@/components/General/BreakdownText.vue';
import { mapGetters } from 'vuex';

export default {
    components: {
        TranslatableButton: TranslatableButton,
        MainNavigation,
        TypableTextarea,
        BreakdownText,
    },
    data() {
        return {
          toSource:false,
          userInput: '',
          response:null,
          loading:false,
          error:''
        };
    },
    computed: {
      ...mapGetters(['languageName', 'language']),
    },
    methods: {
      setInput(input) {
          this.userInput = input
      },
      updateWord(rootId, newWord) {
        console.log("FROM UPDATE")
        console.log(rootId, newWord)
        const updatedResults = this.response.map(word => {
          if (word.root === rootId) {
            return { ...word, ...newWord };
          }
          return word;
        });
        this.response = updatedResults
      },
      changeWord(rootId, level) {
        const updatedResults = this.response.map(word => {
          if (word.root === rootId) {
            return { ...word, level };
          }
          return word;
        });
        this.response = updatedResults
      },
      ignoreWord(rootId) {
        const updatedResults = this.response.map(word => {
          if (word.root === rootId) {
            return { ...word, dontshow: true };
          }
          return word;
        });
        this.response = updatedResults
      },
      async sendSentence() {
        this.loading = true
        try {
          const response = await apiClient.post('/api/RootAnalysis', {
            uID: 1,
            text: this.userInput,
            languageID: this.language,
          });
          console.log(response.data)
          this.loading = false
          this.response=response.data
        } catch (error) {
          this.loading = false
          this.error=error
        }
      },
    }
}
</script>

<style scoped>
.wrapper {
    background-color: white;
    margin: 20px;
    height: 90%;
    border-radius: 15px;
    display:flex;
    justify-content: space-around;
    align-items: center;
    flex-direction:column;
}
div.input{
  justify-content: space-around;
}
.roots{
  width:100%;
}
.textarea{
  width:90%;
}
.inputControl{
  display:flex;
}

@media (max-width: 767px) {
  .wrapper {
    flex-direction:column;
  }
  .input{
    border-right:0;
    border-bottom:2px solid black;
    overflow-y:scroll;
  }
  .input, .output{
    overflow-y:scroll;
  }
}
</style>
