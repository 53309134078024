<template>
  <div>
    <button
    @mouseover="showTranslation = true"
    @mouseleave="showTranslation = false"
    :class="{ 'disabled': disabled || loading }"
    @click="handleClick">
      <span :class="{ 'hide': showTranslation }" v-if="!loading">{{ text }}</span>
      <span :class="{ 'hide': !showTranslation }" v-if="!loading">{{ translation }}</span>
      <span :class="{ 'hide': showTranslation }" v-if="loading">Loading...</span>
      <span :class="{ 'hide': !showTranslation }" v-if="loading">{{ loadingTranslation }}</span>
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ButtonTranslations from './ButtonTranslations';

export default {
  props: {
    disabled: Boolean,
    loading: Boolean,
    text: String
  },
  computed: {
    ...mapState({
      language: state => state.language,
    }),
  },
  data() {
    return {
      showTranslation: false,
      translation: "行く",
      loadingTranslation:"Loading"
    };
  },
  methods: {
    handleClick(event) {
      if (this.disabled) {
        event.preventDefault();
        event.stopPropagation();
      }
    },
    setTranslation() {
      if(this.$store.state.language){
        this.translation = ButtonTranslations[this.text][this.$store.state.language]
        this.loadingTranslation=ButtonTranslations['Loading'][this.$store.state.language]
      }
      else{
        this.translation = ButtonTranslations[this.text]['en']
        this.loadingTranslation = "Loading"
      }

    }
  },
  mounted() {
    this.setTranslation()
  },
  watch: {
    language() {
      this.setTranslation()
    },
  },
};
</script>

<style scoped>
div{
  width:200px;
}
button {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 40px;
  font-size: 15px;
  border-radius: 10px;
  border: 1px solid var(--button-text);
  background-color: var(--button-background);
  color: var(--button-text);
}

button.disabled {
  background-color: var(--disabled-button-background);
}

span {
  position: absolute;
  left: 0;
  right: 0;
  top: 10px;
  transition: transform 0.5s, opacity 0.5s;
  display: block;
}

span.hide {
  transform: translateY(-100%);
  opacity: 0;
}
</style>
