<template>
  <div class="word-container">
    <div>
      <div class="word" v-if="!hasDetail">{{ rootObj.text }} (x{{root.count}})</div>
      <div class="word">{{ rootObj.translation }}</div>
      <div class="word" v-if='rootObj.pronunciation'>{{ rootObj.pronunciation }}</div>
    </div>
    <div v-if="hasDetail">
      <div class="word">{{ root.id }}</div>
      {{ detail.translation }}
      <div class="sentence_wrapper">
        <div class="example_sentence">{{ detail.sentence1 }}</div>
        <div class="example_translation">{{ detail.translation1 }}</div>
      </div>
      <div class="sentence_wrapper">
        <div class="example_sentence">{{ detail.sentence2 }}</div>
        <div class="example_translation">{{ detail.translation2 }}</div>
      </div>
      <div class="sentence_wrapper">
        <div class="example_sentence">{{ detail.sentence3 }}</div>
        <div class="example_translation">{{ detail.translation3 }}</div>
      </div>
    </div>
    <div class="buttons">
        <TranslatableButton @click="updateWord(true)" :loading="loading" text="Known"
          class="wordButton"></TranslatableButton>
        <TranslatableButton @click="updateWord(false)" :loading="loading" text="Unknown"
          class="wordButton"></TranslatableButton>
      <TranslatableButton @click="getDetail()" :loading="loading" text="Detail"
        class="wordButton"></TranslatableButton>
      <TranslatableButton @click="ignoreWord()" :loading="loading" text="Ignore"
        class="wordButton"></TranslatableButton>
    </div>
  </div>
</template>

<script>
import apiClient from '@/api';
import TranslatableButton from '@/components/General/TranslatableButton.vue'

export default {
  props: {
    root: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hasDetail: false,
      detail: {
        translation: '',
        sentence1: '',
        sentence2: '',
        sentence3: '',
        translation1: '',
        translation2: '',
        translation3: '',
      },
      loading: false
    }
  },
  components: {
    TranslatableButton
  },
  computed:{
    rootObj(){
      return this.root.root
    }
  },
  methods: {
    async updateWord(known) {
      if (this.loading) return
      this.loading = true
      try {
        const response = await apiClient.post('/api/RootAnalysis/AddOrUpdateWord', {
          userId: 1,
          rootId: this.root.root.text,
          languageId: this.$store.state.language,
          known: known,
        });
        if (response.status === 200) {
          this.$emit('updateWord', this.root.root.text, known ? 1 : 0)
        }
        this.loading = false
      } catch (error) {
        this.loading = false
        console.error('Error updating word:', error);
      }
    },
    async ignoreWord() {
      this.$emit('ignoreWord', this.root.root.text)
    },
    async getDetail() {
      if (this.loading) return
      this.loading = true
      try {
        const response = await apiClient.get(`/api/RootAnalysis/GetWordDetail?root=${this.root.root.text}&languageId=${this.$store.state.language}`);
        this.detail = response.data;
        this.hasDetail = true
        this.loading = false
      } catch (error) {
        this.loading = false
        console.error("Error fetching word detail:", error);
      }
    },
  },
};
</script>

<style scoped>
.word-container {
  display: flex;
  align-items: center;
  justify-content:space-between;
  margin-bottom: 10px;
  background-color:var(--new-background);
  padding:10px;
  border-radius:10px;
  border:1px solid black;
}

.word {
  padding: 5px;
  margin-bottom: 5px;
  font-size:xx-large;
  color: var(--card-text);
}

.buttons button {
  margin-right: 5px;
}

.word-button {
  width: 150px;
  margin-right: 10px;
}

.buttons>div{
  width:100px;
}
</style>
