<template>
    <MainNavigation>
        <div class="word-assessment">
          <div class="left-panel">
              <div :style="{ backgroundColor: bgColor }" class="word">{{ currentWord }}</div>
              <div :style="{ backgroundColor: bgColor }" class="word">{{ text }}</div>
              <div class="buttons">
                <button @click="startRecording" v-if="!isRecording"><font-awesome-icon icon="microphone"></font-awesome-icon></button>
                <button @click="stopRecording" v-if="isRecording"><font-awesome-icon icon="pause"></font-awesome-icon></button>
                <button @click="sendRecording" :disabled="!recordedBlob || loading"><font-awesome-icon icon="paper-plane"></font-awesome-icon></button>
                <button @click="playRecording" :disabled="!recordedBlob || loading"><font-awesome-icon icon="play"></font-awesome-icon></button>
              </div>
          </div>
          <LetterStats :letterStats="letterStats" />
        </div>
    </MainNavigation>
  </template>
  
  <script>
  import MainNavigation from '@/components/General/MainNavigation.vue'
  import LetterStats from '@/components/LetterPractice/LetterStats.vue';
  import letterWords from '@/components/LetterPractice/letterWords.json'
import apiClient from '@/api';
  
  export default {
    data() {
      return {
        words: [],
        sentences: [],
        currentWord: '',
        audioUrl: '',
        bgColor: 'white',
        letterStats: {},
        isRecording: false,
        recordedBlob: null,
        mediaRecorder: null,
        loading:false,
        text:''
      };
    },
    methods: {
      levenshtein(a, b) {
        const an = a ? a.length : 0;
        const bn = b ? b.length : 0;
        if (an === 0) {
            return bn;
        }
        if (bn === 0) {
            return an;
        }
        const matrix = Array.from(Array(bn + 1), () => Array(an + 1).fill(0));

        for (let i = 0; i <= an; i++) {
            matrix[0][i] = i;
        }
        for (let j = 0; j <= bn; j++) {
            matrix[j][0] = j;
        }
        for (let j = 1; j <= bn; j++) {
            for (let i = 1; i <= an; i++) {
                const cost = a[i - 1] === b[j - 1] ? 0 : 1;
                matrix[j][i] = Math.min(
                    matrix[j - 1][i] + 1, // Deletion
                    matrix[j][i - 1] + 1, // Insertion
                    matrix[j - 1][i - 1] + cost // Substitution
                );
            }
        }
        return matrix[bn][an];
    },

      similarity(a, b) {
          const maxLen = Math.max(a.length, b.length);
          if (maxLen === 0) {
              return 1.0;
          }
          const distance = this.levenshtein(a, b);
          return (maxLen - distance) / maxLen;
      },
      assessPronunciation() {
        return this.similarity(this.currentWord, this.text)
      },
      updateBackgroundColor(accuracy) {
        console.log(accuracy)
        const greenValue = Math.floor(255 * accuracy);
        const redValue = 255 - greenValue;
        this.bgColor = `rgb(${redValue}, ${greenValue}, 0)`;
      },
      nextWord() {
        if (this.words.length > 0) {
          this.currentWord = this.words.shift();
          this.updateLetterStats();
        } else if (this.sentences.length > 0) {
          this.currentWord = this.sentences.shift();
        } else {
          // All done
          alert('All words and sentences completed!');
        }
      },
      updateLetterStats() {
        for (let char of this.currentWord) {
          if (!this.letterStats[char]) {
            this.letterStats[char] = { count: 0, pronunciation: '' };
          }
          this.letterStats[char].count++;
        }
      },
    async startRecording() {
        this.recordedBlob = null;
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.mediaRecorder = new MediaRecorder(stream);
        const chunks = [];
        this.mediaRecorder.ondataavailable = (e) => chunks.push(e.data);
        this.mediaRecorder.onstop = () => {
            this.recordedBlob = new Blob(chunks, { type: 'audio/wav' });
            this.mediaRecorder.stream.getTracks().forEach(track => track.stop());
        };
        this.mediaRecorder.start();
        this.isRecording = true;
    },
    async stopRecording() {
        this.mediaRecorder.stop();
        this.isRecording = false;
    },
    async sendRecording() {
        this.loading=true
        const formData = new FormData();
        formData.append('Audio', this.recordedBlob);
        formData.append('Language', "hi");
        const response = await apiClient.post('/api/RootAnalysis/TranscribeAudio', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if (response.status == 200) {
            const text = await response.data.text;
            this.text = text
            this.loading=false
            console.log("TEXT AND WORD")
            console.log(this.text)
            console.log(this.currentWord)
            let score = this.assessPronunciation()
            this.updateBackgroundColor(score)
            this.$emit('setText', this.text)
            return text;
        } else {
            this.loading=false
            throw new Error('Failed to send voice blob');
        }
    },
    playRecording() {
        const audioUrl = URL.createObjectURL(this.recordedBlob);
        const audio = new Audio(audioUrl);
        audio.play();
    },
    },
    mounted() {
      this.words = letterWords['hn']['words']
      this.nextWord();
    },
    components: {
      LetterStats,
      MainNavigation
    },
  };
  </script>
  
  <style scoped>
  .word-assessment {
    display: flex;
    background-color:white;
    height:100%;
    border-radius:25px;
  }
  .left-panel{
    border-right:1px solid black;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .buttons{
    margin-top:20px;
  }
  .word-assessment>div {
    flex: 1;
  }
  .word {
    font-size: 2em;
    padding: 10px;
  }
  </style>