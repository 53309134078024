<template>
    <div>
        <div v-if="!initializing" class="wrapper">
            <div class="progress-bar">
                <div class="progress" :style="{width:`${progress}%`}">{{this.oglength-this.sentences.length}}</div>
            </div>
            <div>
                <div class="hidden-translation">{{ currentSentence.text }}</div>
                <div class="hidden-translation">{{ currentSentence.translation }}</div>
                <div>
                    <TranslatableButton @click="playAudio()" 
                        :disabled="loading" 
                        text="Play Audio" />
                        There are {{ correctWordsCount }} words
                    </div>
            </div>
            <div class="allWords">
                <div v-for="word in sortedWords" :key="word.rootId" 
                    :class="`word ${(showingAnswers && answers.includes(word.rootId))?'answer':''}
                    ${(selectedWords.includes(word.rootId))?'selected':''}
                    ${(word.correct)?'gottenCorrect':''}`"
                    @click="toggleWord(word.rootId)">
                    <span>{{ word.translation }}
                    <span v-if="showingAnswers && answers.includes(word.rootId)">{{word.rootId}}</span></span>
                    <span v-if="showingAnswers && answers.includes(word.rootId)">{{word.mnemonics}}</span>
                </div>
            </div>
            <TranslatableButton @click="checkSentence()" 
                :disabled="loading" 
                text="Check" />
        </div>
        <div v-if="initializing">
            Loading
        </div>
    </div>
</template>
  
  <script>
  import apiClient from '@/api';
  import { mapGetters } from 'vuex';
  import TranslatableButton from '@/components/General/TranslatableButton.vue'
  
  export default {
    props: {
        lesson: Object,
    },
      components: {
        TranslatableButton
      },
      data() {
        return{
            sentences:null,
            currentSentence: null,
            initializing:true,
            loading:true,
            oglength:0,
            words:null,
            selectedWords:[],
            showingAnswers:false,
            currentAudio:null
        }
      },
      computed: {
        ...mapGetters(['languageName', 'language']),
        progress(){
            return 100-(100*this.sentences.length/this.oglength)
        },
        sortedWords() {
        return this.words.slice().sort((a, b) => {
            return a.translation.localeCompare(b.translation);
        });
        },
        correctWordsCount(){
            let count = 0;
            this.words.forEach(root => {
                if (this.currentSentence.roots.includes(root.rootId)) {
                count++;
                }
            });
            return count
        },
        answers(){
            let sentenceAnswers=[]
            this.words.forEach(root => {
                if (this.currentSentence.roots.includes(root.rootId)) {
                    sentenceAnswers.push(root.rootId)
                }
            });
            return sentenceAnswers
        }
      },
      methods: {
        async playAudio() {
            this.loading = true
            if(this.currentAudio){
                this.currentAudio.play()
            }
            else{
                const response = await fetch(process.env.VUE_APP_API_URL + '/api/RootAnalysis/SynthesizeText', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        text: this.currentSentence.text,
                        languageId: this.lesson.languageId
                    }),
                });
                const blob = await response.blob();
                const audioUrl = URL.createObjectURL(blob);
                const audio = new Audio(audioUrl);
                this.currentAudio = audio 
                this.currentAudio.play()
            }
            // this.currentWord.playAudio();
            this.loading = false
        },
        toggleWord(rootId){
            const index = this.selectedWords.indexOf(rootId);
            if (index === -1 && this.selectedWords.length<this.correctWordsCount) {
                // Value is not in the array, add it
                this.selectedWords.push(rootId);
            } else if(index>-1) {
                // Value is in the array, remove it
                this.selectedWords.splice(index, 1);
            }
        },
        async getSentences(){
            this.initializing=true
            this.words = this.words.filter(word => word.correct==false)
            if(this.words.length==0){
                const response = await apiClient.post('/api/RootAnalysis/UpdateLesson', {
                    lessonId: this.lesson.lessonId,
                    action: "UsedInSound"
                })
                if (response.status === 200) {
                    this.$emit('finishCard')
                }
            }
            try{
            const response = await apiClient.post('/api/RootAnalysis/GetSentences', {
                    words: this.words.map(word=>word.rootId),
                    languageId: this.lesson.languageId
                });
                if (response.status === 200) {
                    console.log(response)
                    this.sentences=response.data
                    this.currentSentence=this.sentences[0]
                    this.oglength = this.sentences.length
                    this.initializing=false
                    this.loading=false
                    this.playAudio()
                }
            } catch (error) {
                this.loading = false
                console.error('Error updating word:', error);
            }
        },
        checkSentence(){
            if(!this.showingAnswers){
                this.showingAnswers=true
                this.words.forEach(root => {
                    if (this.currentSentence.roots.includes(root.rootId)&&this.selectedWords.includes(root.rootId)) {
                        root.correct=true
                    }
                });
            }
            else{
                this.showingAnswers=false
                this.currentAudio=null
                this.sentences.shift();
                this.selectedWords=[]
                if(this.sentences.length==0){
                    this.getSentences()
                }
                else{
                    this.currentSentence=this.sentences[0]
                    this.playAudio()
                }
            }
        }
      },
      mounted(){
        this.words = this.lesson.lessonRoots.map(root => {return{...root.root, correct:false}})
        this.getSentences()
      }
  }
  </script>
  
  <style scoped>
  .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      height:100%;
  }
  .progressWrapper{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
  }
  
  .wrapper>div {
      margin-bottom: 15px;
      font-size: 48px;
  }
  
  .hidden-translation {
      background-color: black;
  }
  
  .hidden-translation:hover {
      background-color: white;
  }
  .rightwrongbuttons, .buttons{
    display:flex;
  }
  .progress-bar{
    width:80%;
    height:25px;
    background-color:red;
  }
  .progress{
    height:100%;
    background-color:green;
    font-size:20px;
    text-align:center;
  }
  .allWords{
    display:flex;
    flex-wrap:wrap;
  }
  .allWords>div{
    border:1px solid black;
    padding:10px;
    display:flex;
    flex-direction:column;
  }
  .gottenCorrect{
    background-color:#DDD;
  }
  .answer{
    background-color:red;
  }
  .selected{
    background-color:blue;
  }
  .selected.answer{
    background-color:green;
  }
  @media (max-width: 767px){
      .wrapper>div {
          font-size:24px;
      }
  }
  </style>
  