import { createRouter, createWebHistory } from 'vue-router';
import LessonWorkflow from '@/views/LessonWorkflow.vue';
import HomePage from '@/views/HomePage.vue';
import SelectionPage from '@/views/SelectionPage.vue';
import WordAssessment from '@/views/WordAssessment.vue';
import TranslationTool from '@/views/TranslationTool.vue';
import LessonsList from '@/views/LessonsList.vue'
import LearnLesson from '@/views/LearnLesson.vue'
import ShadowPage from '@/views/ShadowPage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
  },
  {
    path: '/lesson-workflow',
    name: 'lesson-workflow',
    component: LessonWorkflow,
  },
  {
    path: '/selection',
    name: 'selection',
    component: SelectionPage,
  },
  {
    path: '/letter-practice',
    name: 'letter-practice',
    component: WordAssessment,
  },
  {
    path: '/shadow-practice',
    name: 'shadow-practice',
    component: ShadowPage,
  },
  {
    path: '/translation-tool',
    name: 'translation-tool',
    component: TranslationTool,
  },
  {
    path: '/lessons',
    name: 'lessons',
    component: LessonsList,
  },
  {
    path: '/lesson/:id',
    name: 'lesson',
    component: LearnLesson,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
