<template>
    <div class="wrapper">
        <div>Please use {{ word.translation }} in a sentence</div>

        <TranslatableButton @click="seeTrans = true" :disabled="loading" text="Cheat">
        </TranslatableButton>
        <div v-if="seeTrans||correction">{{ word.rootId }}</div>
        <div v-if="seeTrans||correction">{{ word.pronunciation }}</div>
        <TypableTextarea @setText='setText' ref='textarea'></TypableTextarea>
        <TranslatableButton @click="checkSentence()" :disabled="loading" text="Submit">
        </TranslatableButton>
        {{ correction }}
    </div>
</template>

<script>
//cái áo sơ mi này là đắt
import TranslatableButton from '@/components/General/TranslatableButton.vue'
import apiClient from '@/api';
import TypableTextarea from '@/components/General/TypableTextarea.vue'

export default {
    props: {
        word: Object,
        moveTo: Function,
    },
    components: {
        TranslatableButton,
        TypableTextarea
    },
    data() {
        return {
            seeTrans: false,
            userInput: '',
            correction: '',
            loading: false,
            error: ''
        }
    },
    methods: {
        moveToPosition(position) {
            this.moveTo(position);
        },
        async checkSentence() {
            if (this.loading) return
            this.loading = true
            try {
                const response = await apiClient.post('/api/RootAnalysis/GetCorrection', {
                    languageId: this.$store.state.language,
                    text: this.userInput,
                });
                if (response.status === 200) {
                    this.correction = response.data.correction
                }
                this.loading = false
            } catch (error) {
                this.loading = false
                this.error = error
                console.error('Error updating word:', error);
            }
        },
        setText(text){
          this.userInput=text
        }
    },
    mounted() {

    },
    watch: {
        word(newWord, oldWord) {
            if (newWord !== oldWord) {
                this.loading=false
                this.error=''
                this.correction=null
                this.userInput=''
                this.seeTrans=false
                this.$refs.textarea.clear()
            }
        }
    },
};
</script>

<style scoped>
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.wrapper>div {
    margin-bottom: 15px;
    font-size:36px;
}
@media (max-width: 767px){
    .wrapper>div {
        font-size:24px;
    }
}
</style>
