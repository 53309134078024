import { createStore } from 'vuex';
import languagesData from '@/languages.json';

export default createStore({
  state: {
    language: null,
    languageName: null,
    selectedWords: [],
    seenLearningWords: []
  },
  getters:{
    language: state => state.language,
    languageName: state => state.languageName,
    selectedWords: state => state.selectedWords,
    seenLearningWords: state => state.seenLearningWords
  },
  mutations: {
    setLanguage(state, payload) {
      state.language = payload;
      state.languageName = languagesData.find(obj => obj.id === payload).name;
    },
    addWordToSelection(state, word) {
      if (!state.selectedWords.some(selectedWord => selectedWord.rootId === word.rootId)) {
        state.selectedWords.push(word);
      }
    },
    removeWordFromSelection(state, id) {
      state.selectedWords = state.selectedWords.filter(word => word.rootId !== id);
    },
    setSelection(state, words) {
      state.selectedWords = words;
    },
    addWordsToSeenLearning(state, words) {
      for(let word of words){
        if (!state.seenLearningWords.some(selectedWord => selectedWord.rootId === word.rootId)) {
          state.seenLearningWords.push(word);
        }
      }
    },
  },
});
